import {ReactElement} from "react";
import {useParams} from "react-router";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import Checkout from "./Checkout";
import { useRestaurant } from "@/hooks/useRestaurant";
import { HandoffOptions } from "@devour/client";
import { DEVOUR_RESTAURANT_ID } from "./restaurants/RestaurantPage";

function CheckoutPage(): ReactElement {
    const {menuOrderId: paramMenuOrderId} = useParams<{ menuOrderId: string }>();
    const {data: menuOrder} = useMenuOrder(paramMenuOrderId);
    const queryRestaurant = useRestaurant(menuOrder?.business);
    const isDigitalStore = queryRestaurant.data?.handoffOptions.every(h => h === HandoffOptions.DIGITAL);
    const isDevourStore = DEVOUR_RESTAURANT_ID === menuOrder?.business;
    if (!menuOrder) {
        return;
    }

    return (
        <RestaurantContext.Provider value={{
            restaurantId: menuOrder.business,
            menuOrderId: paramMenuOrderId,
            isDigitalStore,
            isDevourStore,
        }}>
            <Checkout />
        </RestaurantContext.Provider>
    );
}

export default CheckoutPage;


