import {ReactElement, useState} from "react";
import {
    Address,
    AddressBook,
    BusinessesApi,
    HandoffOptions,
    MenuOrdersApi,
    UtilsApi,
    ValidateDeliveryDistanceResponse,
} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {addError, toggleOrderHandoff} from "@/redux/meta/metaActions";
import getConfig from "../../../utils/getConfig";
import {useLocation, useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import CannotDeliverModal from "../../modals/CannotDeliverModal";
import RestaurantAddressAutoPanel from "@/pages/restaurants/components/RestaurantAddressAutoPanel";
import {isAddressBook} from "@/utils/typeGuards";
import HandoffToggle from "@/components/HandoffToggle";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import classNames from "classnames";
import {useRestaurant} from "@/hooks/useRestaurant";

interface Props {
    backToRestaurantPageOnAddressUpdate: boolean;
    onDone?: () => void;
    menuOrderId: string;
    setIsHandoffUpdating?: (isHandoffUpdating: boolean) => void;
    isHandoffUpdating?: boolean;
}

function CheckoutDetailsHandoff(props: Props): ReactElement {

    const location = useLocation();
    const dispatch = useDispatch();
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const paths: Array<string> = location.pathname.split("/").filter(Boolean);
    const {data: menuOrder, refetch: refetchMenuOrder} = useMenuOrder(props.menuOrderId);
    const {data: restaurant} = useRestaurant(menuOrder?.business);
    const navigate = useNavigate();
    const {placeId} = useParams<{ restaurantId?: string; placeId?: string; }>();
    const findSelectedAddressBook = currentUser?.user?.addresses?.find((addressBook) => addressBook.placeId === placeId);
    const [
        showAddressPromptModal,
        setShowAddressPromptModal,
    ] = useState<boolean>(false);
    const [
        showCannotDeliverModal,
        setShowCannotDeliverModal,
    ] = useState<boolean>(false);

    /**
	 * Trigger default values to be applied to the menu order (default tips, etc.)
	 */
    async function updateMenuOrderWithDefaultValues(): Promise<void> {
        try {
            await new MenuOrdersApi(getConfig()).checkoutMenuOrder({
                id: menuOrder.id,
            });
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            await refetchMenuOrder();
        }
    }

    /**
	 * Updates the menu order with the new handoff option and address if provided.
	 * Also updates the menu order with default values (tips, etc.)
	 *
	 * @param option
	 * @param newAddress
	 */
    async function updateExistingShoppingCart(option: HandoffOptions, newAddress?: Address) {
        try {
            props.setIsHandoffUpdating(true);

            await new MenuOrdersApi(getConfig()).updateMenuOrder({
                id: menuOrder.id,
                backgroundCallback: paths[0] === "restaurants"
                    ? "true"
                    : undefined, // Do the background callback on the restaurant menu page but not on checkout page.
                createMenuOrderBody: {
                    handoff: option,
                    tipDelivery: 0,
                    address: newAddress || undefined,
                    deliveryNotes: newAddress && isAddressBook(newAddress)
                        ? newAddress?.deliveryInstructions
                        : undefined,
                    deliveryHandoffInstructions: newAddress && isAddressBook(newAddress)
                        ? newAddress?.handoffInstructions
                        : undefined,
                },
            });
            void refetchMenuOrder();
            dispatch(toggleOrderHandoff(option));

            if (props.onDone) {
                props.onDone();
            }
            await updateMenuOrderWithDefaultValues();
        } catch (e) {
            // Cart removal is being handled by the <DevourCart />
        } finally {
            props.setIsHandoffUpdating(false);
        }
    }

    /**
	 * If user chooses delivery but is not within delivery distance, then prompt them to enter their address.
	 *
	 * @param option
	 */
    async function toggleHandoff(option: HandoffOptions): Promise<void> {
        // If user chooses delivery but is not within delivery distance, then prompt them to enter their address.
        if (option === HandoffOptions.DELIVERY) {
            let validateDistanceRes: ValidateDeliveryDistanceResponse;
            if (menuOrder.address.line1.trim()) {
                validateDistanceRes = await new BusinessesApi(getConfig()).validateDeliveryDistance({
                    id: menuOrder.address.placeId,
                    restaurant: menuOrder.business,
                });
            }

            if (!validateDistanceRes?.canDeliver) {
                setShowAddressPromptModal(true);
                return;
            }
        }

        void updateExistingShoppingCart(option);
    }

    /**
	 * After user enters address in the prompt via choosing "Delivery" when out of range,
	 * check if they are within range now, and if so, update the hand-off option.
	 * Regardless, re-navigate with the new placeid in the url path.
	 *
	 * @param newAddress
	 */
    async function onAddressSubmit(newAddress: Address | AddressBook): Promise<void> {
        try {
            let placeId: string = newAddress?.placeId;
            if (!placeId) {
                const res = await new UtilsApi().getPlaceIdFromAddress({
                    address: newAddress,
                });
                placeId = res.placeId;
            }

            const validateDistanceRes = await new BusinessesApi(getConfig(fullToken)).validateDeliveryDistance({
                id: placeId,
                restaurant: menuOrder.business,
            });

            setShowAddressPromptModal(false);
            // if able to deliver update hand off option
            if (validateDistanceRes.canDeliver) {
                await updateExistingShoppingCart(HandoffOptions.DELIVERY, newAddress);

                // if on the restaurant page, update the path to account for new address
                if (props.backToRestaurantPageOnAddressUpdate && restaurant) {
                    navigate(`${restaurant.url}/${placeId}`, {replace: true});
                    return;
                }
                // Can deliver, so return here and don't display the not deliverable modal
                return;
            }

            setShowCannotDeliverModal(true);
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    function onCannotDeliverChangeAddress(): void {
        setShowCannotDeliverModal(false);
        setShowAddressPromptModal(true);
    }

    // Don't actually need to dispatch any action to switch to pick up because it should already be on pick-up, and
    // can only switch to delivery if they enter a deliverable address
    function onCannotDeliverSwitchToPickUp(): void {
        setShowCannotDeliverModal(false);
    }

    if (!restaurant?.handoffOptions.includes(HandoffOptions.DELIVERY) || !restaurant?.handoffOptions.includes(HandoffOptions.PICKUP)) {
        return null;
    }

    if (!menuOrder) {
        return <div className="checkout-details_switch-skeleton react-loading-skeleton" />;
    }

    return (
        <>
            <CannotDeliverModal
                isOpen={showCannotDeliverModal}
                onNewAddress={onCannotDeliverChangeAddress}
                onSwitchToPickUp={onCannotDeliverSwitchToPickUp}
            />

            <RestaurantAddressAutoPanel
                title="Choose a Closer Delivery Address"
                defaultAddressBook={findSelectedAddressBook}
                isOpen={showAddressPromptModal}
                onClose={() => setShowAddressPromptModal(false)}
                onAddressSubmit={onAddressSubmit}
                modalOnTablet={true}
            />

            <div className={classNames("checkout-details_handoff", {
                "checkout-details_handoff_no-cursor": props.isHandoffUpdating,
            })}>
                <HandoffToggle
                    onHandoffToggle={toggleHandoff}
                    disabledDelivery={!restaurant?.handoffOptions.includes(HandoffOptions.DELIVERY)}
                    disabledPickup={!restaurant?.handoffOptions.includes(HandoffOptions.PICKUP)}
                    handoff={menuOrder.handoff}
                />
            </div>
        </>
    );
}

CheckoutDetailsHandoff.defaultProps = {
    isHandoffUpdating: false,
    setIsHandoffUpdating: () => {},
};

export default CheckoutDetailsHandoff;
