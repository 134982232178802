import {LoadStates} from "@/components/loadDpay/LoadStates";
import {DpayPurchase, TokenPaymentMethod} from "@devour/client";
import {ButtonHTMLAttributes, ReactElement} from "react";
import {useNavigate} from "react-router";
import {HiSparkles} from "react-icons/hi2";
import {formatPriceAddCommaPreserveDecimals} from "@/utils/currencyFormatters";
import {StripePaymentMethodObject} from "@/types/Stripe";
import FrameButton from "@/components/buttons/FrameButton";
import {capitalize} from "lodash";
import { FaCheckCircle } from "react-icons/fa";

interface Props {
    setLoadState: (state: LoadStates) => void;
    dpayPurchase?: DpayPurchase;
    toggleAsModal?: () => void;
    payment: StripePaymentMethodObject;
}

function LoadDpaySuccess(props: Props): ReactElement {
    const navigate = useNavigate();
    const today = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
    }).format(new Date());

    const tokenName = import.meta.env.VITE_TOKEN_NAME;

    function onViewWallet() {
        navigate("/withdraw");
    }

    return (
        <div className="buy-dpay-page_panel_success">
            {props.toggleAsModal ? <FaCheckCircle/> : <HiSparkles/>}
            <h3>{props.toggleAsModal ? `${tokenName} Load Completed` : "Congratulations!"}</h3>
            <p className="buy-dpay-page_panel_success_text-content">
                {props.toggleAsModal
                    ? <>
                        You have successfully loaded {tokenName} on your account and it is ready for you to view the balance. Thank you!
                    </>
                    : <>
                        You have successfully snagged {tokenName}!
                        Your {tokenName} is now fully loaded on your account,
                        and it is ready for you to see. Thank you!
                    </>}
            </p>
            <div className="buy-dpay-page_panel_success_summary">
                <h4>Summary</h4>
                <div className="buy-dpay-page_panel_success_summary_amounts">
                    <div>
                        Paid price
                        <p>
                            <strong>
                                {props.dpayPurchase?.paymentMethod === TokenPaymentMethod.XP
                                    ? `${props.dpayPurchase?.xp} XP`
                                    : `$${props.dpayPurchase?.fiat.toFixed(2)} USD`}
                            </strong>
                        </p>
                    </div>
                    <div>
                        Received
                        <p><strong>{formatPriceAddCommaPreserveDecimals(props.dpayPurchase?.dpay)} {tokenName}</strong></p>
                    </div>
                </div>
                <div className="buy-dpay-page_panel_success_summary_others">
                    <div className="buy-dpay-page_panel_success_summary_others_date">
                        Date
                        <span>{today}</span>
                    </div>
                    <div className="buy-dpay-page_panel_success_summary_others_payment">
                        {props.dpayPurchase?.paymentMethod === TokenPaymentMethod.FIAT &&
                            <>
                                Payment Method
                                <span>{capitalize(props.payment?.card?.brand)}****{props.payment?.card?.last4}</span>
                            </>
                        }
                    </div>
                </div>
            </div>
            <FrameButton
                <ButtonHTMLAttributes<HTMLButtonElement>>
                color="purple"
                size="large"
                onClick={props.toggleAsModal ?? onViewWallet}
            >
                {props.toggleAsModal ? "Close" : `View ${tokenName} Balance`}
            </FrameButton>
            <div
                className="buy-dpay-page_panel_success_confirm-btn"
                onClick={() => props.setLoadState(LoadStates.SELECT_PAYMENT)}
            >Load More {import.meta.env.VITE_TOKEN_NAME}</div>
        </div>
    );
}

export default LoadDpaySuccess;
