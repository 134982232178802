/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    CreateDpayCsvDataBody,
    CreateDpayCsvDataBodyFromJSON,
    CreateDpayCsvDataBodyToJSON,
    CreateMenuOrderBody,
    CreateMenuOrderBodyFromJSON,
    CreateMenuOrderBodyToJSON,
    CreateMenuOrderResponse,
    CreateMenuOrderResponseFromJSON,
    CreateMenuOrderResponseToJSON,
    GetMenuOrdersDpayDataResponse,
    GetMenuOrdersDpayDataResponseFromJSON,
    GetMenuOrdersDpayDataResponseToJSON,
    GetMenuOrdersOwnResponse,
    GetMenuOrdersOwnResponseFromJSON,
    GetMenuOrdersOwnResponseToJSON,
    GetMenuOrdersOwnSort,
    GetMenuOrdersOwnSortFromJSON,
    GetMenuOrdersOwnSortToJSON,
    GetMenuOrdersResponse,
    GetMenuOrdersResponseFromJSON,
    GetMenuOrdersResponseToJSON,
    GetMenuOrdersSort,
    GetMenuOrdersSortFromJSON,
    GetMenuOrdersSortToJSON,
    InlineResponse20012,
    InlineResponse20012FromJSON,
    InlineResponse20012ToJSON,
    InlineResponse20013,
    InlineResponse20013FromJSON,
    InlineResponse20013ToJSON,
    InlineResponse20014,
    InlineResponse20014FromJSON,
    InlineResponse20014ToJSON,
    InlineResponse20015,
    InlineResponse20015FromJSON,
    InlineResponse20015ToJSON,
    MenuOrder,
    MenuOrderFromJSON,
    MenuOrderToJSON,
    MenuOrderStatus,
    MenuOrderStatusFromJSON,
    MenuOrderStatusToJSON,
    OrderCancelBody,
    OrderCancelBodyFromJSON,
    OrderCancelBodyToJSON,
    OrderRefundBody,
    OrderRefundBodyFromJSON,
    OrderRefundBodyToJSON,
    RefundAutoMenuOrderBody,
    RefundAutoMenuOrderBodyFromJSON,
    RefundAutoMenuOrderBodyToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
    SubmitMenuOrderBody,
    SubmitMenuOrderBodyFromJSON,
    SubmitMenuOrderBodyToJSON,
} from '../models';

export interface CancelMenuOrderRequest {
    id: string;
    orderCancelBody?: OrderCancelBody;
}

export interface CancelMenuOrderCheckRequest {
    id: string;
}

export interface CheckoutMenuOrderRequest {
    id: string;
}

export interface CompleteDigitalMenuOrderRequest {
    id: string;
}

export interface CreateMenuOrderRequest {
    skipOnError?: string;
    createMenuOrderBody?: CreateMenuOrderBody;
}

export interface GenerateMenuOrderDpayDataRequest {
    createDpayCsvDataBody?: CreateDpayCsvDataBody;
}

export interface GetMenuOrderRequest {
    id: string;
    validateOrderItems?: string;
}

export interface GetMenuOrderAdminRequest {
    id: string;
}

export interface GetMenuOrderChatRequest {
    id: string;
}

export interface GetMenuOrderDiscountsRequest {
    id: string;
}

export interface GetMenuOrderValidationRequest {
    id: string;
}

export interface GetMenuOrdersAllRequest {
    orderId?: string;
    business?: string;
    discount?: string;
    customer?: string;
    recipient?: string;
    dateStart?: string;
    dateEnd?: string;
    status?: Array<MenuOrderStatus>;
    sort?: GetMenuOrdersSort;
    order?: SortOrder;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetMenuOrdersOwnRequest {
    status?: Array<MenuOrderStatus>;
    sort?: GetMenuOrdersOwnSort;
    order?: SortOrder;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface RefundAutoMenuOrderRequest {
    id: string;
    refundAutoMenuOrderBody?: RefundAutoMenuOrderBody;
}

export interface RefundMenuOrderRequest {
    id: string;
    orderRefundBody?: OrderRefundBody;
}

export interface SubmitMenuOrderRequest {
    brandMapSlug?: string;
    submitMenuOrderBody?: SubmitMenuOrderBody;
}

export interface TransferPaymentMenuOrderRequest {
    id: string;
}

export interface UpdateMenuOrderRequest {
    id: string;
    backgroundCallback?: string;
    skipOnError?: string;
    createMenuOrderBody?: CreateMenuOrderBody;
}

export interface VerifyMenuOrderRequest {
    id: string;
    brandMapSlug?: string;
}

/**
 * MenuOrdersApi - interface
 * @export
 * @interface MenuOrdersApiInterface
 */
export interface MenuOrdersApiInterface {
    /**
     * Used to cancel menu order
     * @param {string} id 
     * @param {OrderCancelBody} [orderCancelBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuOrdersApiInterface
     */
    cancelMenuOrderRaw(requestParameters: CancelMenuOrderRequest): Promise<runtime.ApiResponse<MenuOrder>>;

    /**
     * Used to cancel menu order
     */
    cancelMenuOrder(requestParameters: CancelMenuOrderRequest): Promise<MenuOrder>;

    /**
     * Used to do a preliminary check to see if menu order is cancellable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuOrdersApiInterface
     */
    cancelMenuOrderCheckRaw(requestParameters: CancelMenuOrderCheckRequest): Promise<runtime.ApiResponse<InlineResponse20012>>;

    /**
     * Used to do a preliminary check to see if menu order is cancellable
     */
    cancelMenuOrderCheck(requestParameters: CancelMenuOrderCheckRequest): Promise<InlineResponse20012>;

    /**
     * Used to set default values (tip amount, etc.) when customer goes to checkout.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuOrdersApiInterface
     */
    checkoutMenuOrderRaw(requestParameters: CheckoutMenuOrderRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to set default values (tip amount, etc.) when customer goes to checkout.
     */
    checkoutMenuOrder(requestParameters: CheckoutMenuOrderRequest): Promise<void>;

    /**
     * Used to mark the digital menu order as completed.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuOrdersApiInterface
     */
    completeDigitalMenuOrderRaw(requestParameters: CompleteDigitalMenuOrderRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to mark the digital menu order as completed.
     */
    completeDigitalMenuOrder(requestParameters: CompleteDigitalMenuOrderRequest): Promise<void>;

    /**
     * Used to create new menu order
     * @param {string} [skipOnError] Whether to skip the order item if an error is detected instead of throwing the error.
     * @param {CreateMenuOrderBody} [createMenuOrderBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuOrdersApiInterface
     */
    createMenuOrderRaw(requestParameters: CreateMenuOrderRequest): Promise<runtime.ApiResponse<CreateMenuOrderResponse>>;

    /**
     * Used to create new menu order
     */
    createMenuOrder(requestParameters: CreateMenuOrderRequest): Promise<CreateMenuOrderResponse>;

    /**
     * Used to get all dpay related data for the admin to generate csv reports.
     * @param {CreateDpayCsvDataBody} [createDpayCsvDataBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuOrdersApiInterface
     */
    generateMenuOrderDpayDataRaw(requestParameters: GenerateMenuOrderDpayDataRequest): Promise<runtime.ApiResponse<GetMenuOrdersDpayDataResponse>>;

    /**
     * Used to get all dpay related data for the admin to generate csv reports.
     */
    generateMenuOrderDpayData(requestParameters: GenerateMenuOrderDpayDataRequest): Promise<GetMenuOrdersDpayDataResponse>;

    /**
     * Used to get menu order
     * @param {string} id 
     * @param {string} [validateOrderItems] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuOrdersApiInterface
     */
    getMenuOrderRaw(requestParameters: GetMenuOrderRequest): Promise<runtime.ApiResponse<MenuOrder>>;

    /**
     * Used to get menu order
     */
    getMenuOrder(requestParameters: GetMenuOrderRequest): Promise<MenuOrder>;

    /**
     * Used by admin to get menu order
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuOrdersApiInterface
     */
    getMenuOrderAdminRaw(requestParameters: GetMenuOrderAdminRequest): Promise<runtime.ApiResponse<MenuOrder>>;

    /**
     * Used by admin to get menu order
     */
    getMenuOrderAdmin(requestParameters: GetMenuOrderAdminRequest): Promise<MenuOrder>;

    /**
     * Used by customer to get the chat log between customer & support for a menu order
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuOrdersApiInterface
     */
    getMenuOrderChatRaw(requestParameters: GetMenuOrderChatRequest): Promise<runtime.ApiResponse<InlineResponse20013>>;

    /**
     * Used by customer to get the chat log between customer & support for a menu order
     */
    getMenuOrderChat(requestParameters: GetMenuOrderChatRequest): Promise<InlineResponse20013>;

    /**
     * Used by customer to get a list of qualifying discounts for their menu order
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuOrdersApiInterface
     */
    getMenuOrderDiscountsRaw(requestParameters: GetMenuOrderDiscountsRequest): Promise<runtime.ApiResponse<InlineResponse20014>>;

    /**
     * Used by customer to get a list of qualifying discounts for their menu order
     */
    getMenuOrderDiscounts(requestParameters: GetMenuOrderDiscountsRequest): Promise<InlineResponse20014>;

    /**
     * Used to validate a menu order prior to calling blockchain functions.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuOrdersApiInterface
     */
    getMenuOrderValidationRaw(requestParameters: GetMenuOrderValidationRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to validate a menu order prior to calling blockchain functions.
     */
    getMenuOrderValidation(requestParameters: GetMenuOrderValidationRequest): Promise<void>;

    /**
     * Used by admins to get a list of all menu orders in the system
     * @param {string} [orderId] 
     * @param {string} [business] 
     * @param {string} [discount] Used to a list of all paid orders that used this discount. You can pass in \&quot;DPAY\&quot; to ID param.
     * @param {string} [customer] 
     * @param {string} [recipient] 
     * @param {string} [dateStart] 
     * @param {string} [dateEnd] 
     * @param {Array<MenuOrderStatus>} [status] 
     * @param {GetMenuOrdersSort} [sort] 
     * @param {SortOrder} [order] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuOrdersApiInterface
     */
    getMenuOrdersAllRaw(requestParameters: GetMenuOrdersAllRequest): Promise<runtime.ApiResponse<GetMenuOrdersResponse>>;

    /**
     * Used by admins to get a list of all menu orders in the system
     */
    getMenuOrdersAll(requestParameters: GetMenuOrdersAllRequest): Promise<GetMenuOrdersResponse>;

    /**
     * Used by user to get a list of all their menu orders
     * @param {Array<MenuOrderStatus>} [status] 
     * @param {GetMenuOrdersOwnSort} [sort] 
     * @param {SortOrder} [order] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuOrdersApiInterface
     */
    getMenuOrdersOwnRaw(requestParameters: GetMenuOrdersOwnRequest): Promise<runtime.ApiResponse<GetMenuOrdersOwnResponse>>;

    /**
     * Used by user to get a list of all their menu orders
     */
    getMenuOrdersOwn(requestParameters: GetMenuOrdersOwnRequest): Promise<GetMenuOrdersOwnResponse>;

    /**
     * Used by admin to trigger an auto refund if the order has an error or was cancelled.
     * @param {string} id 
     * @param {RefundAutoMenuOrderBody} [refundAutoMenuOrderBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuOrdersApiInterface
     */
    refundAutoMenuOrderRaw(requestParameters: RefundAutoMenuOrderRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by admin to trigger an auto refund if the order has an error or was cancelled.
     */
    refundAutoMenuOrder(requestParameters: RefundAutoMenuOrderRequest): Promise<void>;

    /**
     * Used by admin to refund a menu order.
     * @param {string} id 
     * @param {OrderRefundBody} [orderRefundBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuOrdersApiInterface
     */
    refundMenuOrderRaw(requestParameters: RefundMenuOrderRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by admin to refund a menu order.
     */
    refundMenuOrder(requestParameters: RefundMenuOrderRequest): Promise<void>;

    /**
     * Used to submit the menu order to the system
     * @param {string} [brandMapSlug] 
     * @param {SubmitMenuOrderBody} [submitMenuOrderBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuOrdersApiInterface
     */
    submitMenuOrderRaw(requestParameters: SubmitMenuOrderRequest): Promise<runtime.ApiResponse<InlineResponse20015>>;

    /**
     * Used to submit the menu order to the system
     */
    submitMenuOrder(requestParameters: SubmitMenuOrderRequest): Promise<InlineResponse20015>;

    /**
     * Used by admin to pay a merchant for a menu order that was paid to Devour.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuOrdersApiInterface
     */
    transferPaymentMenuOrderRaw(requestParameters: TransferPaymentMenuOrderRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by admin to pay a merchant for a menu order that was paid to Devour.
     */
    transferPaymentMenuOrder(requestParameters: TransferPaymentMenuOrderRequest): Promise<void>;

    /**
     * Used to update menu order
     * @param {string} id 
     * @param {string} [backgroundCallback] Whether to do the menu calculation callback in the background and not awaited.
     * @param {string} [skipOnError] Whether to skip the order item if an error is detected instead of throwing the error.
     * @param {CreateMenuOrderBody} [createMenuOrderBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuOrdersApiInterface
     */
    updateMenuOrderRaw(requestParameters: UpdateMenuOrderRequest): Promise<runtime.ApiResponse<CreateMenuOrderResponse>>;

    /**
     * Used to update menu order
     */
    updateMenuOrder(requestParameters: UpdateMenuOrderRequest): Promise<CreateMenuOrderResponse>;

    /**
     * Verify that the payment has been successfully processed and redirects the customer to the order success / fail page.
     * @param {string} id 
     * @param {string} [brandMapSlug] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuOrdersApiInterface
     */
    verifyMenuOrderRaw(requestParameters: VerifyMenuOrderRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Verify that the payment has been successfully processed and redirects the customer to the order success / fail page.
     */
    verifyMenuOrder(requestParameters: VerifyMenuOrderRequest): Promise<void>;

}

/**
 * no description
 */
export class MenuOrdersApi extends runtime.BaseAPI implements MenuOrdersApiInterface {

    /**
     * Used to cancel menu order
     */
    async cancelMenuOrderRaw(requestParameters: CancelMenuOrderRequest): Promise<runtime.ApiResponse<MenuOrder>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cancelMenuOrder.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/menu-orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: OrderCancelBodyToJSON(requestParameters.orderCancelBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MenuOrderFromJSON(jsonValue));
    }

    /**
     * Used to cancel menu order
     */
    async cancelMenuOrder(requestParameters: CancelMenuOrderRequest): Promise<MenuOrder> {
        const response = await this.cancelMenuOrderRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to do a preliminary check to see if menu order is cancellable
     */
    async cancelMenuOrderCheckRaw(requestParameters: CancelMenuOrderCheckRequest): Promise<runtime.ApiResponse<InlineResponse20012>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cancelMenuOrderCheck.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/menu-orders/{id}/cancel`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20012FromJSON(jsonValue));
    }

    /**
     * Used to do a preliminary check to see if menu order is cancellable
     */
    async cancelMenuOrderCheck(requestParameters: CancelMenuOrderCheckRequest): Promise<InlineResponse20012> {
        const response = await this.cancelMenuOrderCheckRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to set default values (tip amount, etc.) when customer goes to checkout.
     */
    async checkoutMenuOrderRaw(requestParameters: CheckoutMenuOrderRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling checkoutMenuOrder.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/menu-orders/{id}/checkout`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to set default values (tip amount, etc.) when customer goes to checkout.
     */
    async checkoutMenuOrder(requestParameters: CheckoutMenuOrderRequest): Promise<void> {
        await this.checkoutMenuOrderRaw(requestParameters);
    }

    /**
     * Used to mark the digital menu order as completed.
     */
    async completeDigitalMenuOrderRaw(requestParameters: CompleteDigitalMenuOrderRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling completeDigitalMenuOrder.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/menu-orders/{id}/complete-digital`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to mark the digital menu order as completed.
     */
    async completeDigitalMenuOrder(requestParameters: CompleteDigitalMenuOrderRequest): Promise<void> {
        await this.completeDigitalMenuOrderRaw(requestParameters);
    }

    /**
     * Used to create new menu order
     */
    async createMenuOrderRaw(requestParameters: CreateMenuOrderRequest): Promise<runtime.ApiResponse<CreateMenuOrderResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.skipOnError !== undefined) {
            queryParameters['skipOnError'] = requestParameters.skipOnError;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/menu-orders/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMenuOrderBodyToJSON(requestParameters.createMenuOrderBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateMenuOrderResponseFromJSON(jsonValue));
    }

    /**
     * Used to create new menu order
     */
    async createMenuOrder(requestParameters: CreateMenuOrderRequest): Promise<CreateMenuOrderResponse> {
        const response = await this.createMenuOrderRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get all dpay related data for the admin to generate csv reports.
     */
    async generateMenuOrderDpayDataRaw(requestParameters: GenerateMenuOrderDpayDataRequest): Promise<runtime.ApiResponse<GetMenuOrdersDpayDataResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/menu-orders/admin/generate-dpay-data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDpayCsvDataBodyToJSON(requestParameters.createDpayCsvDataBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMenuOrdersDpayDataResponseFromJSON(jsonValue));
    }

    /**
     * Used to get all dpay related data for the admin to generate csv reports.
     */
    async generateMenuOrderDpayData(requestParameters: GenerateMenuOrderDpayDataRequest): Promise<GetMenuOrdersDpayDataResponse> {
        const response = await this.generateMenuOrderDpayDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get menu order
     */
    async getMenuOrderRaw(requestParameters: GetMenuOrderRequest): Promise<runtime.ApiResponse<MenuOrder>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMenuOrder.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.validateOrderItems !== undefined) {
            queryParameters['validateOrderItems'] = requestParameters.validateOrderItems;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/menu-orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MenuOrderFromJSON(jsonValue));
    }

    /**
     * Used to get menu order
     */
    async getMenuOrder(requestParameters: GetMenuOrderRequest): Promise<MenuOrder> {
        const response = await this.getMenuOrderRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used by admin to get menu order
     */
    async getMenuOrderAdminRaw(requestParameters: GetMenuOrderAdminRequest): Promise<runtime.ApiResponse<MenuOrder>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMenuOrderAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/menu-orders/{id}/admin`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MenuOrderFromJSON(jsonValue));
    }

    /**
     * Used by admin to get menu order
     */
    async getMenuOrderAdmin(requestParameters: GetMenuOrderAdminRequest): Promise<MenuOrder> {
        const response = await this.getMenuOrderAdminRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used by customer to get the chat log between customer & support for a menu order
     */
    async getMenuOrderChatRaw(requestParameters: GetMenuOrderChatRequest): Promise<runtime.ApiResponse<InlineResponse20013>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMenuOrderChat.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/menu-orders/{id}/chat`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20013FromJSON(jsonValue));
    }

    /**
     * Used by customer to get the chat log between customer & support for a menu order
     */
    async getMenuOrderChat(requestParameters: GetMenuOrderChatRequest): Promise<InlineResponse20013> {
        const response = await this.getMenuOrderChatRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used by customer to get a list of qualifying discounts for their menu order
     */
    async getMenuOrderDiscountsRaw(requestParameters: GetMenuOrderDiscountsRequest): Promise<runtime.ApiResponse<InlineResponse20014>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMenuOrderDiscounts.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/menu-orders/{id}/discounts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20014FromJSON(jsonValue));
    }

    /**
     * Used by customer to get a list of qualifying discounts for their menu order
     */
    async getMenuOrderDiscounts(requestParameters: GetMenuOrderDiscountsRequest): Promise<InlineResponse20014> {
        const response = await this.getMenuOrderDiscountsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to validate a menu order prior to calling blockchain functions.
     */
    async getMenuOrderValidationRaw(requestParameters: GetMenuOrderValidationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMenuOrderValidation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/menu-orders/validate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to validate a menu order prior to calling blockchain functions.
     */
    async getMenuOrderValidation(requestParameters: GetMenuOrderValidationRequest): Promise<void> {
        await this.getMenuOrderValidationRaw(requestParameters);
    }

    /**
     * Used by admins to get a list of all menu orders in the system
     */
    async getMenuOrdersAllRaw(requestParameters: GetMenuOrdersAllRequest): Promise<runtime.ApiResponse<GetMenuOrdersResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.orderId !== undefined) {
            queryParameters['orderId'] = requestParameters.orderId;
        }

        if (requestParameters.business !== undefined) {
            queryParameters['business'] = requestParameters.business;
        }

        if (requestParameters.discount !== undefined) {
            queryParameters['discount'] = requestParameters.discount;
        }

        if (requestParameters.customer !== undefined) {
            queryParameters['customer'] = requestParameters.customer;
        }

        if (requestParameters.recipient !== undefined) {
            queryParameters['recipient'] = requestParameters.recipient;
        }

        if (requestParameters.dateStart !== undefined) {
            queryParameters['dateStart'] = requestParameters.dateStart;
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['dateEnd'] = requestParameters.dateEnd;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/menu-orders/all/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMenuOrdersResponseFromJSON(jsonValue));
    }

    /**
     * Used by admins to get a list of all menu orders in the system
     */
    async getMenuOrdersAll(requestParameters: GetMenuOrdersAllRequest): Promise<GetMenuOrdersResponse> {
        const response = await this.getMenuOrdersAllRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used by user to get a list of all their menu orders
     */
    async getMenuOrdersOwnRaw(requestParameters: GetMenuOrdersOwnRequest): Promise<runtime.ApiResponse<GetMenuOrdersOwnResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/menu-orders/own/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMenuOrdersOwnResponseFromJSON(jsonValue));
    }

    /**
     * Used by user to get a list of all their menu orders
     */
    async getMenuOrdersOwn(requestParameters: GetMenuOrdersOwnRequest): Promise<GetMenuOrdersOwnResponse> {
        const response = await this.getMenuOrdersOwnRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used by admin to trigger an auto refund if the order has an error or was cancelled.
     */
    async refundAutoMenuOrderRaw(requestParameters: RefundAutoMenuOrderRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling refundAutoMenuOrder.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/menu-orders/{id}/refund-auto`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefundAutoMenuOrderBodyToJSON(requestParameters.refundAutoMenuOrderBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by admin to trigger an auto refund if the order has an error or was cancelled.
     */
    async refundAutoMenuOrder(requestParameters: RefundAutoMenuOrderRequest): Promise<void> {
        await this.refundAutoMenuOrderRaw(requestParameters);
    }

    /**
     * Used by admin to refund a menu order.
     */
    async refundMenuOrderRaw(requestParameters: RefundMenuOrderRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling refundMenuOrder.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/menu-orders/{id}/refund`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderRefundBodyToJSON(requestParameters.orderRefundBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by admin to refund a menu order.
     */
    async refundMenuOrder(requestParameters: RefundMenuOrderRequest): Promise<void> {
        await this.refundMenuOrderRaw(requestParameters);
    }

    /**
     * Used to submit the menu order to the system
     */
    async submitMenuOrderRaw(requestParameters: SubmitMenuOrderRequest): Promise<runtime.ApiResponse<InlineResponse20015>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.brandMapSlug !== undefined) {
            queryParameters['brandMapSlug'] = requestParameters.brandMapSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/menu-orders/submit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubmitMenuOrderBodyToJSON(requestParameters.submitMenuOrderBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20015FromJSON(jsonValue));
    }

    /**
     * Used to submit the menu order to the system
     */
    async submitMenuOrder(requestParameters: SubmitMenuOrderRequest): Promise<InlineResponse20015> {
        const response = await this.submitMenuOrderRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used by admin to pay a merchant for a menu order that was paid to Devour.
     */
    async transferPaymentMenuOrderRaw(requestParameters: TransferPaymentMenuOrderRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling transferPaymentMenuOrder.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/menu-orders/{id}/transfer-payment`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by admin to pay a merchant for a menu order that was paid to Devour.
     */
    async transferPaymentMenuOrder(requestParameters: TransferPaymentMenuOrderRequest): Promise<void> {
        await this.transferPaymentMenuOrderRaw(requestParameters);
    }

    /**
     * Used to update menu order
     */
    async updateMenuOrderRaw(requestParameters: UpdateMenuOrderRequest): Promise<runtime.ApiResponse<CreateMenuOrderResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMenuOrder.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.backgroundCallback !== undefined) {
            queryParameters['backgroundCallback'] = requestParameters.backgroundCallback;
        }

        if (requestParameters.skipOnError !== undefined) {
            queryParameters['skipOnError'] = requestParameters.skipOnError;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/menu-orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMenuOrderBodyToJSON(requestParameters.createMenuOrderBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateMenuOrderResponseFromJSON(jsonValue));
    }

    /**
     * Used to update menu order
     */
    async updateMenuOrder(requestParameters: UpdateMenuOrderRequest): Promise<CreateMenuOrderResponse> {
        const response = await this.updateMenuOrderRaw(requestParameters);
        return await response.value();
    }

    /**
     * Verify that the payment has been successfully processed and redirects the customer to the order success / fail page.
     */
    async verifyMenuOrderRaw(requestParameters: VerifyMenuOrderRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling verifyMenuOrder.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.brandMapSlug !== undefined) {
            queryParameters['brandMapSlug'] = requestParameters.brandMapSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/menu-orders/{id}/verify`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Verify that the payment has been successfully processed and redirects the customer to the order success / fail page.
     */
    async verifyMenuOrder(requestParameters: VerifyMenuOrderRequest): Promise<void> {
        await this.verifyMenuOrderRaw(requestParameters);
    }

}
