import {ReactElement, useState} from "react";
import {Address, UtilsApi, ErrorType} from "@devour/client";
import {
    addError, updateLastSearchedPlaceId,
} from "@/redux/meta/metaActions";
import {useDispatch, useSelector} from "react-redux";
import {BiTargetLock} from "react-icons/bi";
import {FaCheckCircle} from "react-icons/fa";
import GeolocationAddressBookSaveModal from "@/components/modals/GeolocationAddressBookSaveModal";
import {IStore} from "@/redux/defaultStore";
import classNames from "classnames";
import { useStreamerMode } from "@/hooks/useStreamerMode";

interface Props {
    submitAddressCallback: (newAddress: Address) => void;
    setAsDefault?: boolean;
}

function RestaurantAddressAutoPanelGeolocate(props: Props): ReactElement {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { hideCharacters } = useStreamerMode();
    const [geolocationLoading, setGeolocationLoading] = useState<boolean>(false);
    const [geolocationAddress, setGeolocationAddress] = useState<Address>();
    const [confirmAddressBook, setConfirmAddressBook] = useState<boolean>(false);

    async function onGeolocation(position: GeolocationPosition): Promise<Address> {
        try {
            const res = await new UtilsApi().getAddressFromLatLng({
                latitude: position.coords.latitude.toString(),
                longitude: position.coords.longitude.toString(),
            });
            dispatch(updateLastSearchedPlaceId(res.placeId));
            setGeolocationAddress(res);
            return res;
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    async function onQueryCurrentPosition() {
        if (!navigator.geolocation) {
            dispatch(await addError({
                type: ErrorType.APP,
                message: "Geolocation is not supported by this browser.",
            }));
            return;
        }

        setGeolocationLoading(true);

        try {
            /*
             * `navigator.geolocation.getCurrentPosition` isn't an actual promise.
             * Need to wrap with a real promise and await to show loading screen.
             */
            const position: GeolocationPosition = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });
            return await onGeolocation(position);
            // navigator.geolocation.getCurrentPosition(onGeolocation);
        } catch (e) {
            dispatch(await addError({
                type: ErrorType.APP,
                message: e.message,
            }));
        } finally {
            setGeolocationLoading(false);
        }
    }

    async function onCurrentPosition() {
        if (geolocationAddress) {
            if (props.setAsDefault) {
                props.submitAddressCallback(geolocationAddress);
                return;
            }
            if (fullToken) {
                setConfirmAddressBook(true);
            } else {
                onConfirmAddressBookSubmit(geolocationAddress);
            }
        } else {
            const newAddress = await onQueryCurrentPosition();

            if (props.setAsDefault) {
                props.submitAddressCallback(newAddress);
            }
        }
    }

    function onConfirmAddressBookSubmit(address: Address): void {
        props.submitAddressCallback(address);
        setConfirmAddressBook(false);
    }

    return (
        <>
            <GeolocationAddressBookSaveModal
                isOpen={confirmAddressBook}
                address={geolocationAddress}
                onDone={onConfirmAddressBookSubmit}
                onClose={() => setConfirmAddressBook(false)}
            />
            <div className="restaurant-search-address-bottom-panel_body_geolocate">
                <button
                    className={classNames("restaurant-search-address-bottom-panel_body_geolocate_button", {
                        "is-active": geolocationAddress,
                    })}
                    onClick={onCurrentPosition}
                    type="button"
                >
                    <div className="restaurant-search-address-bottom-panel_body_geolocate_button_lock-icon">
                        <BiTargetLock/>
                    </div>
                    {geolocationAddress
                        ? <>
                            <div className="restaurant-search-address-bottom-panel_body_geolocate_button_details">
                                {hideCharacters(geolocationAddress.line1)}, {hideCharacters(geolocationAddress.locality)}, {hideCharacters(geolocationAddress.administrativeArea)}
                            </div>
                            <div className="restaurant-search-address-bottom-panel_body_geolocate_button_current-location">
								Current Location
                            </div>
                            <div className="restaurant-search-address-bottom-panel_body_geolocate_button_icon">
                                <FaCheckCircle/>
                            </div>
                        </>
					 : <div className="restaurant-search-address-bottom-panel_body_geolocate_button_use-location">
							Use current location
                        </div>
                    }
                    {geolocationLoading &&
                    <div className="restaurant-search-address-bottom-panel_body_geolocate_button_spinner"/>
                    }
                </button>
            </div>
        </>
    );

}

export default RestaurantAddressAutoPanelGeolocate;
