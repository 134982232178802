import {
    ButtonHTMLAttributes,
    ChangeEvent,
    Dispatch,
    Fragment,
    ReactElement,
    SetStateAction,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import {
    GetMenuResponse,
    MenuCategory,
    MenuItem,
    MenuOrderItem,
    ValidateDeliveryDistanceResponse,
} from "@devour/client";
import {useParams} from "react-router";
import MenuItemCard from "./MenuItemCard";
import {RestaurantContext} from "../context/RestaurantContext";
import RestaurantMenusJumper from "@/pages/restaurants/components/RestaurantMenusJumper";
import {useRestaurantPreviousOrderedItems} from "@/hooks/useRestaurantPrevouslyOrderedItems";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import RestaurantMenusOrderAgain from "@/pages/restaurants/components/RestaurantMenusOrderAgain";
import {isDesktop, isTablet} from "react-device-detect";
import SearchInput from "@/components/inputs/SearchInput";
import Divider from "@/components/Divider";
import classNames from "classnames";
import FrameButton from "@/components/buttons/FrameButton";
import {FaShoppingCart} from "react-icons/fa";
import {useRestaurant} from "@/hooks/useRestaurant";

interface Props {
    validateDeliveryDistanceResponse?: ValidateDeliveryDistanceResponse;
    setMobileSearchMode: Dispatch<SetStateAction<boolean>>;
    restaurantMenu: GetMenuResponse;
    toggleShowCartPanel: () => void;
    updateMenuOrderItemManager: (key: MenuOrderItem[]) => void;
    menuOrderItemsManager: { [key: string]: MenuOrderItem };
}

function RestaurantMenusBrowse(props: Props): ReactElement {

    const {restaurantId, isDigitalStore} = useContext(RestaurantContext);
    const {placeId} = useParams<{ placeId: string; }>();
    const {data: restaurant} = useRestaurant(restaurantId, placeId);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const isSidebarModalActive = useSelector((store: IStore) => store.metaStore.sidebarModalActive);
    const queryRestaurantPreviousOrderedItems = useRestaurantPreviousOrderedItems(restaurantId, fullToken);
    const menuCategoriesRoot = props.restaurantMenu?.menus?.filter((category) => category.isEnabled);
    const [
        searchValue,
        setSearchValue,
    ] = useState("");

    const [
        onSticky,
        setOnSticky,
    ] = useState<boolean>(false);

    const jumperRef = useRef(null);

    const sumQuantity: number = Object.keys(props.menuOrderItemsManager || {})
        .map(k => props.menuOrderItemsManager[k].quantity)
        .reduce((acc, curr) => acc + curr, 0) || 0;


    // Check if jumper is sticky or not to apply classes conditionally
    useEffect(() => {
        const handleScroll = () => {
            if (isDesktop || isTablet) {
                const divTop = jumperRef.current?.getBoundingClientRect()?.top;
                if (divTop <= 0) {
                    setOnSticky(true);
                } else {
                    setOnSticky(false);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    function renderMenuItems(menuItems: Array<MenuItem> = []): Array<JSX.Element> {
        return menuItems
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .filter((menuItem) => filterItem(menuItem, searchValue))
            .map((menuItem) => <li
                key={menuItem.id}
                className="restaurant-menus-browse_category_list_item"
            >
                <MenuItemCard
                    restaurantMenu={props.restaurantMenu}
                    placeId={placeId}
                    menuItem={menuItem}
                    updateMenuOrderItemManager={props.updateMenuOrderItemManager}
                    menuOrderItemsManager={props.menuOrderItemsManager}
                />
            </li>);
    }

    function renderMenuCategory(category: MenuCategory, isLast: boolean, nestedLevel: number = 2): JSX.Element {
        const menuItems = category.menuItems?.filter((menuItem) => menuItem.isEnabled);
        let heading: JSX.Element;
        switch (nestedLevel) {
            case 2:
                heading = <h2>{category?.name}</h2>;
                break;
            case 3:
                heading = <h3>{category?.name}</h3>;
                break;
            case 4:
                heading = <h4>{category?.name}</h4>;
                break;
            case 5:
                heading = <h5>{category?.name}</h5>;
                break;
            case 6:
            default:
                heading = <h6>{category?.name}</h6>;
                break;
        }

        return (
            <Fragment key={category.id}>
                <div
                    id={`menu-category-${category.id}`}
                    className="restaurant-menus-browse_category restaurant-page_section-margin"
                >
                    {props.restaurantMenu && menuItems?.length > 0 &&
                    <>
                        {heading}
                        <ul className="restaurant-menus-browse_category_list">
                            {renderMenuItems(category.menuItems)}
                        </ul>
                    </>
                    }
                    {category.menuSubcategories?.sort((a, b) => a.sortOrder - b.sortOrder)
                        .filter((subcategory) => subcategory.isEnabled)
                        .map((subcategory, index, array) => renderMenuCategory(subcategory, index === array.length - 1, nestedLevel + 1))
                    }
                </div>
                {!isLast && <Divider/>}
            </Fragment>
        );
    }

    /**
     * Get only categories & sub categories than have any items that match the user's search.
     *
     * @param _sortedRootCats
     */
    function getFilteredCategories(_sortedRootCats: Array<MenuCategory>): Array<MenuCategory> {
        if (!_sortedRootCats) {
            return [];
        }
        return _sortedRootCats.reduce((acc: Array<MenuCategory>, category) => {
            const foundMenuItems = category.menuItems?.filter(item => filterItem(item, searchValue));

            if (foundMenuItems.length > 0) {
                acc.push(category);
            }

            if (category.menuSubcategories.length > 0) {
                acc = acc.concat(getFilteredCategories(category.menuSubcategories));
            }

            return acc;
        }, []);
    }

    function filterItem(item: MenuItem, search: string): boolean {
        if (!item.isEnabled) {
            return false;
        }
        return item?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
            item.description?.toLowerCase()?.includes(search.toLowerCase());
    }

    function handleSearchChange(e: ChangeEvent<HTMLInputElement>): void {
        setSearchValue(e.target.value);
    }

    function clearSearch(): void {
        setSearchValue("");
    }

    if (!restaurant) {
        return null;
    }

    const filteredCategories = getFilteredCategories(menuCategoriesRoot);

    return (
        <div className="restaurant-menus-browse">

            <div className="restaurant-menus-browse_results">

                {isDesktop
                    ? <div
                        ref={jumperRef}
                        className={classNames(
                            "restaurant-menus-browse_jumper-search-container",
                            {
                                "restaurant-menus-browse_jumper-search-container_toggle-sticky": !isSidebarModalActive, // stop sticky from displaying on top of sidebar modals
                                "restaurant-menus-browse_jumper-sticky": onSticky, // bottom border spans page width when sticky
                            },
                        )}>
                        <div>
                            <RestaurantMenusJumper
                                searchValue={searchValue}
                                setMobileSearchMode={props.setMobileSearchMode}
                                restaurantMenu={props.restaurantMenu}
                                onSticky={onSticky}
                            />
                            <div className="restaurant-menus-browse_jumper-search-container_input">
                                <SearchInput
                                    placeholder={isDigitalStore ? "Search an item" : "Search restaurant menu"}
                                    value={searchValue}
                                    onChange={handleSearchChange}
                                    maxLength={15}
                                    onClear={clearSearch}
                                    className="restaurant-menus-browse_jumper-search-container_input"
                                />
                            </div>

                            {onSticky &&
                            <FrameButton
                                <ButtonHTMLAttributes<HTMLButtonElement>>
                                onClick={props.toggleShowCartPanel}
                                color="purple"
                                size="narrow"
                                leftIcon={FaShoppingCart}
                                forwardProps={{type: "button"}}
                            >
                                {sumQuantity}
                            </FrameButton>
                            }
                        </div>
                    </div>
                    : <div
                        className={classNames(
                            "restaurant-menus-jumper",
                            {
                                "restaurant-menus-jumper_toggle-sticky": !isSidebarModalActive, // stop sticky from displaying on top of sidebar modals
                                "restaurant-menus-browse_jumper-sticky": onSticky, // bottom border spans page width when sticky
                            },
                        )}
                    >
                        {isTablet &&
                        <div className="restaurant-menus-browse_jumper-search-container_input">
                            <SearchInput
                                placeholder="Search restaurant menu"
                                value={searchValue}
                                onChange={handleSearchChange}
                                maxLength={15}
                                onClear={clearSearch}
                                className="restaurant-menus-browse_jumper-search-container_input"
                            />
                        </div>
                        }
                        <RestaurantMenusJumper
                            searchValue={searchValue}
                            setMobileSearchMode={props.setMobileSearchMode}
                            restaurantMenu={props.restaurantMenu}
                            onSticky={onSticky}
                        />
                    </div>
                }
                {queryRestaurantPreviousOrderedItems.data &&
                <>
                    <RestaurantMenusOrderAgain
                        restaurantMenu={props.restaurantMenu}
                        searchValue={searchValue}
                        updateMenuOrderItemManager={props.updateMenuOrderItemManager}
                        menuOrderItemsManager={props.menuOrderItemsManager}
                    />
                </>
                }

                {filteredCategories.length > 0 &&
                filteredCategories
                    .sort((a, b) => a.sortOrder - b.sortOrder)
                    .map((category, index, array) => renderMenuCategory(category, index === array.length - 1, 2))
                }

                {menuCategoriesRoot?.length === 0 &&
                <div className="restaurant-menus-browse_category restaurant-page_section-margin">
                    <p className="restaurant-menus-browse_results_empty-message">
                            No menus are active at this current time. Please try again later.
                    </p>
                </div>
                }

                {menuCategoriesRoot?.length > 0 && filteredCategories.length === 0 &&
                <div className="restaurant-menus-browse_category restaurant-page_section-margin">
                    <p className="restaurant-menus-browse_results_empty-message">
                            No items on this menu match your current search.
                    </p>
                </div>
                }
            </div>
        </div>
    );
}

export default RestaurantMenusBrowse;
