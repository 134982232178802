import {useMutation, useQueryClient} from "@tanstack/react-query";
import {
    Address,
    DeliveryHandoffInstructions,
    HandoffOptions,
    MenuOrderItem,
    MenuOrdersApi,
} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {
    addMenuOrder,
    refreshMenuOrderCart,
} from "@/redux/meta/metaActions";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import * as Sentry from "@sentry/react";

interface CreateMenuOrderItemsFormInit {
    restaurantId: string;
    skipOnError?: boolean;
}

interface UpdateMenuOrderItemsFormValues {
    deliveryNotes?: string;
    deliveryHandoffInstructions?: DeliveryHandoffInstructions;
    address?: Address;
    orderItems: Array<MenuOrderItem>;
    handoff?: HandoffOptions;
}

/**
 * Create the menu order with food items
 *
 * @param options
 */
export function useCreateMenuOrderItems(options: CreateMenuOrderItemsFormInit) {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const handoff = useSelector((store: IStore) => store.metaStore.handoff);

    return useMutation({
        mutationFn: async (formValues: UpdateMenuOrderItemsFormValues) => {
            return Sentry.startSpan({
                name: "useCreateMenuOrderItems",
            }, async () => {
                const newMenuOrder = await new MenuOrdersApi(getConfig()).createMenuOrder({
                    skipOnError: options.skipOnError ? "true" : undefined,
                    createMenuOrderBody: {
                        business: options.restaurantId,
                        handoff: formValues.handoff || handoff,
                        address: formValues.address,
                        deliveryNotes: formValues.deliveryNotes,
                        deliveryHandoffInstructions: formValues.deliveryHandoffInstructions,
                        orderItems: formValues.orderItems,
                    },
                });

                dispatch(addMenuOrder(options.restaurantId, newMenuOrder.id));
                dispatch(refreshMenuOrderCart());
                /*
                 * Update the menuOrder data cache with the new updated menu order
                 * No need refetchMenuOrder. This eliminates the need to
                 * refetch the menu order from the database, increasing speed
                 */
                queryClient.setQueryData([
                    "menuOrder",
                    newMenuOrder.id,
                ], newMenuOrder);
                return newMenuOrder;
            });
        },
        // onError: async (e) => dispatch(await addError(e as unknown as ApiError)),
    });
}