import {ReactElement} from "react";
import LoadDpayPanel from "@/components/loadDpay/LoadDpayPanel";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useGetTransactions} from "@/hooks/useGetTransactions";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";

interface Props {
    isOpen: boolean;
    size?: "xs" | "sm" | "md" | "lg" | "xl" | "xs2" | "sm2" | "xs1" | "max";
    toggle: () => void;
}

function CheckoutPaymentLoadDpayModal(props: Props): ReactElement {

    const currentUser = useSelector((store: IStore) => store.metaStore?.currentUser);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {refetch: refetchTransactionData} = useGetTransactions(fullToken, currentUser?.user?.id);

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="contact-modal load-fuel-modal"
            size={props.size ?? "sm2"}
        >
            <LoadDpayPanel
                toggleAsModal={async () => {
                    props.toggle();
                    await refetchTransactionData();
                }}
            />

        </FrameOneModal>
    );
}

export default CheckoutPaymentLoadDpayModal;
