import {ReactElement, useEffect} from "react";
import {IoClose} from "react-icons/io5";
import useWindowSize, {WindowBreakpointSizes} from "@/hooks/useWindowSize";
import {useDispatch, useSelector} from "react-redux";
import {useGetTransactions} from "@/hooks/useGetTransactions";
import {IStore} from "@/redux/defaultStore";
import {TransactionSubject} from "@devour/client";
import {updateDpayPriceExpiryTime, updateIsCheckoutFuelBannerDismissed} from "@/redux/meta/metaActions";
import FrameButton from "../buttons/FrameButton";

function getScreenWidth(windowWidth): WindowBreakpointSizes {
    if (windowWidth >= 1920) {
        return WindowBreakpointSizes.XLARGE;
    } if (windowWidth >= 1280) {
        return WindowBreakpointSizes.LARGE;
    } if (windowWidth >= 768) {
        return WindowBreakpointSizes.MEDIUM; // tablet
    } if (windowWidth >= 414) {
        return WindowBreakpointSizes.SMALL; // large mobile
    }

    return WindowBreakpointSizes.XSMALL; // small mobile
}


interface Props {
    isOnDigitalStore?: boolean;
    onLoadClicked?: () => void;
}

export default function CheckoutFuelPromoBanner(props: Props): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const isCheckoutFuelBannerDismissed = useSelector((store: IStore) => store.metaStore.isCheckoutFuelBannerDismissed);
    const dispatch = useDispatch();

    const {data: transactionData} = useGetTransactions(fullToken, currentUser?.user?.id);
    const windowSize = useWindowSize();
    const windowWidth = getScreenWidth(windowSize[0]);

    if (isCheckoutFuelBannerDismissed) {
        return null;
    }


    const orderTokenReward = transactionData?.transactions?.find(transaction => transaction.subject === TransactionSubject.MENUORDERDPAYPAYMENTREWARD);
    if (orderTokenReward && !props.isOnDigitalStore) {
        return null;
    }


    const textContent = `Pay with ${import.meta.env.VITE_TOKEN_NAME} and unlock ${import.meta.env.VITE_TOKEN_NAME} Back
                        rewards ${windowSize[0] > 768 ? "for your orders" : ""}!`;

    return (
        <div
            className="checkout-fuel-banner"
            style={{
                backgroundImage: `url(${import.meta.env.VITE_CDN_URL}/images/checkout-FUEL-bnr-${windowWidth}.webp)`,
            }}
        >
            <div className="checkout-fuel-banner_text">
                <h4>Earn 5% {import.meta.env.VITE_TOKEN_NAME} Back Now</h4>
                <div className="checkout-fuel-banner_text_subtitle">
                    {textContent}
                </div>
            </div>

            {props.onLoadClicked
                ? <FrameButton
                    color="white-drop-shadow-devour"
                    size="narrow"
                    onClick={() => props.onLoadClicked()}
                    className="checkout-fuel-banner_load-btn"
                >
                    <span>Load {import.meta.env.VITE_TOKEN_NAME}</span>
                </FrameButton>
                : <button
                    onClick={() => dispatch(updateIsCheckoutFuelBannerDismissed(true))}
                    className="reset-button checkout-fuel-banner_close-btn"
                >
                    <IoClose/>
                </button>}

        </div>
    );
}
