import {ButtonHTMLAttributes, ReactElement, useContext, useEffect, useRef, useState} from "react";
import CheckoutPageHeader from "@/components/checkout/CheckoutPageHeader";
import CheckoutPageBackLink from "@/components/checkout/CheckoutPageBackLink";
import {useParams} from "react-router";
import {
    addDevourIqMenuOrderError,
    addError,
    addMenuOrder,
    addMenuOrderError,
    decrementLoading,
    incrementLoading,
    refreshMenuOrderCart,
    removeDpayPriceExpiryTime,
    removeMenuOrder,
    updateLastMenuOrderId,
} from "@/redux/meta/metaActions";
import {useDispatch, useSelector} from "react-redux";
import CheckoutPaymentMethods from "@/components/checkout/checkoutPayments/CheckoutPaymentMethods";
import CheckoutOrderSummary from "@/components/checkout/checkoutOrderSummary/CheckoutOrderSummary";
import CheckoutDetails from "@/components/checkout/checkoutDetails/CheckoutDetails";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import {IStore} from "@/redux/defaultStore";
import FrameButton from "@/components/buttons/FrameButton";
import {FaArrowRight} from "react-icons/fa";
import {MenuOrder, MenuOrdersApi, MenuOrderStatus, UtilsApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import Toast from "@/components/Toast";
import {magic} from "@/utils/magic";
import {logout} from "@/redux/auth/authActions";
import {useNavigate} from "react-router-dom";
import {BigNumberish, getBigInt} from "ethers";
import {getMagicDpayBalance} from "@/utils/checkoutDpayFunctions/getMagicDpayBalance";
import {getMagicDecimals} from "@/utils/checkoutDpayFunctions/getMagicDecimals";
import {sendDpayTransaction} from "@/utils/checkoutDpayFunctions/sendDpayTransaction";
import {waitForMagicTransaction} from "@/utils/checkoutDpayFunctions/waitForMagicTransaction";
import {useAccount, useReadContract} from "wagmi";
import {dpayAbi} from "@/abis/dpayAbi";
import RefreshModal from "@/components/modals/RefreshModal";
import {getBlocksFromMenuOrder} from "@/utils/checkoutDpayFunctions/getBlocksFromMenuOrder";
import CheckoutPaymentErrorModal from "@/components/checkout/checkoutPayments/CheckoutPaymentErrorModal";
import {getMenuOrderPaymentMethod} from "@/utils/getMenuOrderPaymentMethod";
import {useGetStripePaymentMethodList} from "@/hooks/useGetStripePaymentMethodList";
import {StripePaymentMethodObject} from "@/types/Stripe";
import {useGetTransactions} from "@/hooks/useGetTransactions";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import {useDpayTransaction} from "@/hooks/menuOrder/useDpayTransaction";
import {getApiErrorMessage} from "@/utils/getApiErrorMessage";
import MenuOrderErrorModal from "@/pages/restaurants/components/MenuOrderErrorModal";
import DevourIQMenuOrderErrorModal from "./restaurants/components/DevourIqMenuOrderModal";
import {BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";
import { useRestaurant } from "@/hooks/useRestaurant";
import { IoIosWarning } from "react-icons/io";


function Checkout(): ReactElement {
    const {menuOrderId: contextMenuOrderId, embeddedMenu, setCheckoutState, isDigitalStore} = useContext(RestaurantContext);
    const {menuOrderId: paramMenuOrderId, slug: brandMapSlug} = useParams<{ menuOrderId?: string, slug?: string }>();
    const menuOrderId = paramMenuOrderId || contextMenuOrderId;
    const {data: menuOrder, refetch: refetchMenuOrder} = useMenuOrder(menuOrderId);
    const restaurant = menuOrder?.business;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const account = useAccount();

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore?.currentUser);
    const disableCartSubmitButton = useSelector((store: IStore) => store.metaStore.loadingIncrement);
    const menuOrdersRefresh = useSelector((store: IStore) => store.metaStore.menuOrdersRefresh);

    const [showOnchainToast, setShowOnchainToast] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [paymentError, setPaymentError] = useState<string>("");
    const [showPaymentErrorModal, setShowPaymentErrorModal] = useState<boolean>(false);
    const [externalTransactionHash, setExternalTransactionHash] = useState<string>(undefined);
    const [externalDpay, setExternalDpay] = useState<string>(undefined);
    const {data: restaurantData} = useRestaurant(restaurant);

    const [isMenuOrderCorrect, setIsMenuOrderCorrect] = useState<boolean>(false);

    const [blocks, setBlocks] = useState<number>(0);
    const isCheckoutInProgress = useRef(false);

    const {data: dpayDecimals, isSuccess: dpayDecimalsIsSuccess} = useReadContract({
        address: import.meta.env.VITE_DPAY_TOKEN_ADDRESS_ETHEREUM as `0x${string}`,
        abi: dpayAbi,
        query: {enabled: account.isConnected},
        functionName: "decimals",
    });

    const {data: balanceOfDpay} = useReadContract({
        address: import.meta.env.VITE_DPAY_TOKEN_ADDRESS_ETHEREUM as `0x${string}`,
        abi: dpayAbi,
        query: {enabled: account.isConnected},
        functionName: "balanceOf",
        args: [account.address],
    });

    const {errorMessage: errorMsg, write, transactionHash, status, prepareLoading} = useDpayTransaction({
        externalDpay: externalDpay,
    });

    const {data: transactionData} = useGetTransactions(fullToken, currentUser?.user?.id);

    const {data: paymentMethodData} = useGetStripePaymentMethodList(fullToken);
    const paymentMethods = (paymentMethodData?.paymentMethods) as Array<StripePaymentMethodObject>;
    const menuOrderPaymentMethod = getMenuOrderPaymentMethod(menuOrder, paymentMethods, account, transactionData);

    useEffect(() => {
        if (transactionHash) {
            setExternalTransactionHash(transactionHash);
        }

    }, [transactionHash]);

    useEffect(() => {
        if (menuOrderId && menuOrder?.status) {
            setIsMenuOrderCorrect(false);
            if (menuOrder.status === MenuOrderStatus.PAYMENT) {
                void createNewMenuOrderAndRedirect();
            } else {
                void checkoutMenuOrder();
            }
        }
    }, [
        menuOrderId,
        menuOrder?.status,
    ]);

    useEffect(() => {
        if (menuOrder?.validationErrorMessage) {
            dispatch(addMenuOrderError({
                errorMessage: menuOrder.validationErrorMessage,
                restaurantId: menuOrder.business,
            }));
        }
    }, [menuOrder?.validationErrorMessage]);

    useEffect(() => {
        if (menuOrder?.devourIqNotification) {
            dispatch(addDevourIqMenuOrderError({
                errorMessage: menuOrder.devourIqNotification,
                restaurantId: menuOrder.business,
            }));
        }
    }, [menuOrder?.devourIqNotification]);

    useEffect(() => {
        setBlocks(getBlocksFromMenuOrder(menuOrder));
    }, [
        menuOrder?.onChainDpay,
        menuOrder?.dpayFiatAtOrderTime,
    ]);

    useEffect(() => {
        if (externalDpay && !prepareLoading) {
            dispatch(incrementLoading());
            void write();
        }
    }, [
        externalDpay,
        prepareLoading,
    ]);

    useEffect(() => {
        if (status === "success" && externalTransactionHash) {
            void handleExternalWalletOrder();
        }
    }, [
        status,
        externalTransactionHash,
    ]);

    useEffect(() => {
        if (menuOrdersRefresh) {
            void refetchMenuOrder();
        }
    }, [menuOrdersRefresh]);

    useEffect(() => {
        if (errorMsg) {
            dispatch(decrementLoading());
            setShowErrorModal(true);
        }
    }, [errorMsg]);

    /**
     * Checks out the menu order.
     */
    async function checkoutMenuOrder(): Promise<void> {
        if (!menuOrderId) {
            return;
        }
        try {
            dispatch(updateLastMenuOrderId(menuOrder?.id));
            await new MenuOrdersApi(getConfig()).checkoutMenuOrder({
                id: menuOrderId,
            });
            await refetchMenuOrder();
            setIsMenuOrderCorrect(true);

        } catch (e) {
            dispatch(await addError(e));
        }
    }

    async function createNewMenuOrderAndRedirect(): Promise<void> {
        let newMenuOrderId: string;
        try {
            const newMenuOrder: MenuOrder = await new MenuOrdersApi(getConfig()).createMenuOrder({
                createMenuOrderBody: {
                    ...menuOrder,
                    business: menuOrder.business,
                },
            });
            dispatch(removeDpayPriceExpiryTime());

            newMenuOrderId = newMenuOrder?.id;

            if (brandMapSlug) {
                dispatch(addMenuOrder(newMenuOrder?.business, newMenuOrderId));
                dispatch(refreshMenuOrderCart());
            } else {
                navigate(`/checkout/${newMenuOrder?.id}`);
            }

        } catch {
            if (brandMapSlug && newMenuOrderId) {
                dispatch(removeMenuOrder(newMenuOrderId));
            }
            // Fail silently
        }
    }

    /**
     * Validates a transaction hash if an onChain order was made.
     * @param transactionHash
     */
    async function validateTransactionHash(transactionHash: string): Promise<void> {

        if (!transactionHash || transactionHash === "") {
            throw new Error("There was a problem with your order - missing transaction hash. Please try again.");
        }

        // Initial check on the front-end, another check will happen on the back-end
        const transactionData =
            await new UtilsApi().getDpayOnchainTransaction({
                id: transactionHash,
            });

        if (!transactionData.dpayRecipient ||
            transactionData.dpayRecipient !== import.meta.env.VITE_DPAY_CHECKOUT_RECIPIENT_ADDRESS) {
            throw new Error("Error matching transaction hash. Please try again, and contact support if issue persists.");
        }

        if (!menuOrder.isMagicWallet && menuOrder.onChainDpay > 0) {
            setExternalTransactionHash(transactionHash);
        }

    }

    /**
     * Handles an order submitted with Magic wallet (on-chain) payments.
     */
    async function handleMagicWalletPayment(): Promise<void | string> {
        const isMagicConnected = await magic.user.isLoggedIn();

        // If there is a problem with the user's Magic connection, log them out
        if (!isMagicConnected) {
            dispatch(logout());
            navigate("/log-in");
            throw new Error("There was a problem connecting to your DevourGO wallet. Please try logging in again, and contact support if the issue persists.");

        } else {
            const magicDecimals: bigint = await getMagicDecimals();

            // Need to round because BigNumber can't handle decimals
            const roundedDpay = Math.round(menuOrder.onChainDpay);

            const realAmountOfDpay: bigint = BigInt(roundedDpay) * BigInt(10) ** magicDecimals;

            const realDpayBalance: bigint = await getMagicDpayBalance() * BigInt(10) ** magicDecimals;

            if (realDpayBalance < realAmountOfDpay) {
                throw new Error(`You don't have enough ${import.meta.env.VITE_TOKEN_NAME} in your DevourGO wallet for this transaction.`);
            }

            const receipt = await sendDpayTransaction(realAmountOfDpay);

            let transactionIsSafe = false;

            if (!(receipt && receipt.blockNumber && receipt.hash)) {
                throw new Error("There was a problem sending your transaction. Please try again.");
            }

            transactionIsSafe = await waitForMagicTransaction(receipt, blocks);

            // Wait to enough blocks to pass
            if (!transactionIsSafe) {
                throw new Error("Your transaction has been rejected for security reasons. Please try again.");
            }

            // Send transaction hash to backend
            await validateTransactionHash(receipt.hash);

            return receipt.hash;
        }

    }

    /**
     * Handles an order submitted with external wallet (on-chain) payments.
     * Called after the on-chain order is actually processed (this takes care of it
     * within the Devour system)
     */
    async function handleExternalWalletOrder() {
        try {
            const res = await new MenuOrdersApi(getConfig()).submitMenuOrder({
                submitMenuOrderBody: {
                    id: menuOrderId,
                    hash: externalTransactionHash,
                },
            });

            setExternalTransactionHash(undefined);

            // for the brand map page's embedded checkout, won't redirect to menu order success/error page
            if (embeddedMenu) {
                handleUpdateBrandMapCheckoutState(res.checkoutUrl);
                return;
            }

            window.location.href = res.checkoutUrl;

        } catch (e) {
            const errorMessage = await getApiErrorMessage(e);
            setShowPaymentErrorModal(true);
            setPaymentError(errorMessage);
        } finally {
            dispatch(decrementLoading());
            isCheckoutInProgress.current = false;
        }
    }

    /**
     * Handles the on-chain portion of external wallet payments.
     * Note that due to the async nature of these actions, it is handled through
     * useEffects - this triggers the flow.
     */
    function handleExternalWalletPayment(): void {
        // Handle external wallet

        // Need to round because BigNumber can't handle decimals
        const roundedDpay = Math.round(menuOrder.onChainDpay);

        // Check if wallet is connected
        if (!account.isConnected) {
            throw new Error("You are not connected to your external wallet. Please re-connect before trying again.");
        }

        // Check if decimals loaded successfully - should happen automatically if the wallet is connected
        if (!(dpayDecimalsIsSuccess && dpayDecimals)) {
            // This should NEVER happen so would be critical they reach out to us
            throw new Error("There was an error reading the contract. Please contact support if re-connecting your wallet doesn't solve this problem.");
        }

        if (BigInt(roundedDpay) > getBigInt(balanceOfDpay as BigNumberish)) {
            throw new Error(`You don't have enough ${import.meta.env.VITE_TOKEN_NAME} in your external wallet for this transaction.`);
        }

        const realAmountOfDpay: bigint = BigInt(roundedDpay) * BigInt(10) ** getBigInt(dpayDecimals as BigNumberish);


        setExternalDpay(realAmountOfDpay.toString());

        // due to the async nature of the rest of the flow, it must be handled through useEffects
    }

    /**
     * Handles on-chain payments, depending on what wallet was used (Magic or external).
     */
    async function handleOnChainPayments() {

        // Check if on-chain payments are active
        if (menuOrder.onChainDpay > 0) {

            // If so then confirm that order is valid before beginning any on-chain transactions
            await new MenuOrdersApi(getConfig(fullToken)).getMenuOrderValidation({
                id: menuOrder.id,
            });

            // Toast to let customers know on-chain takes a bit of time -- after order is validated
            setShowOnchainToast(true);

            // Handle on-chain payments depending on wallet type used
            if (menuOrder.isMagicWallet) {
                return await handleMagicWalletPayment();

            }
            handleExternalWalletPayment();


        }
    }

    /**
     * Handles submitting the menu order.
     */
    async function onPlaceOrder(): Promise<void> {
        dispatch(incrementLoading());
        isCheckoutInProgress.current = true;

        try {
            // Handle on-chain payments first. Will only return transaction hash if magic.
            const transactionHash = await handleOnChainPayments();
            // Handle everything except for external wallet payments here
            if (menuOrder.isMagicWallet || !menuOrder.onChainDpay) {
                const res = await new MenuOrdersApi(getConfig(fullToken)).submitMenuOrder({
                    submitMenuOrderBody: {
                        id: menuOrderId,
                        hash: transactionHash || "",
                    },
                    brandMapSlug: brandMapSlug,
                });

                // for the brand map page's embedded checkout, won't redirect to menu order success/error page
                if (embeddedMenu && !menuOrder.isCoinbase) {
                    handleUpdateBrandMapCheckoutState(res.checkoutUrl);
                    return;
                }
                window.location.href = res.checkoutUrl;
            }
        } catch (e) {
            const errorMessage = await getApiErrorMessage(e);
            setShowPaymentErrorModal(true);
            setPaymentError(errorMessage);
        } finally {
            dispatch(decrementLoading());
            isCheckoutInProgress.current = false;
        }

    }

    function disableSubmitButton(): boolean {
        return menuOrder?.exceededSlippage || !menuOrderPaymentMethod && menuOrder?.stripeTotal > 0 || !menuOrder.callbackComplete || !!disableCartSubmitButton;
    }

    function renderSubmitOrderButton(): ReactElement {
        return (
            <>
                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="purple"
                    size="large"
                    onClick={onPlaceOrder}
                    className="checkout-page_submit-btn"
                    forwardProps={{
                        type: "button",
                        disabled: disableSubmitButton(),
                    }}
                >
                Place order
                    <div className="checkout-page_submit-btn_total">
                    ${menuOrder.stripeTotal.toFixed(2)}
                        <FaArrowRight/>
                    </div>
                </FrameButton>
                {isDigitalStore && <div className="checkout-page_refund-alert">
                    <IoIosWarning />
                    <span>We are unable to provide refunds for {restaurantData.name} purchases.</span>
                </div>}
            </>
        );
    }

    function handleOnchainToastDismissal() {
        setShowOnchainToast(false);
    }

    function onClosePaymentError(): void {
        setShowPaymentErrorModal(false);
    }

    function handleUpdateBrandMapCheckoutState(checkoutUrl: string) {
        const checkoutUrlParts = checkoutUrl.split("/");

        if (checkoutUrlParts[checkoutUrlParts.length - 1] === "success") {
            setCheckoutState(BrandMapStates.SUCCESS);
        } else {
            setCheckoutState(BrandMapStates.FAILURE);
        }
    }

    if (!menuOrder) {
        return;
    }

    return (
        <>
            <MenuOrderErrorModal />
            <DevourIQMenuOrderErrorModal />
            <RefreshModal
                isOpen={showErrorModal}
                error={errorMsg}
            />
            <CheckoutPaymentErrorModal
                isOpen={showPaymentErrorModal}
                errorMessage={paymentError}
                toggle={onClosePaymentError}
            />
            <Toast
                message="Hold on tight, on-chain payments take some time! Don't navigate away or refresh the page."
                isOpen={showOnchainToast}
                onDismiss={handleOnchainToastDismissal}
                remainIndefinitely={true}
                removeMarginAdjustment={true}
            />
            {!restaurant || !menuOrder || !isMenuOrderCorrect
                ? <div className="menu-order-checkout-page_loader">
                    <div className="spinner"/>
                </div>
                : <>
                    <div className="checkout-page">

                        {isDesktop && !embeddedMenu && <CheckoutPageHeader/>}

                        <div className="checkout-page_wrapper">

                            {isDesktop && <CheckoutPageBackLink/>}

                            <div className="checkout-page_wrapper_content">

                                <CheckoutDetails/>
                                <CheckoutPaymentMethods isCheckoutInProgress={isCheckoutInProgress}/>
                                <div
                                    className="checkout-page_wrapper_content_right">
                                    <CheckoutOrderSummary/>
                                    {renderSubmitOrderButton()}
                                </div>
                            </div>

                        </div>
                        <div className="checkout-page-mobile-submit">
                            {renderSubmitOrderButton()}
                        </div>
                    </div>

                </>
            }
        </>
    );
}

export default Checkout;

