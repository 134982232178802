/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum HandoffOptions {
    DELIVERY = 'DELIVERY',
    PICKUP = 'PICKUP',
    DIGITAL = 'DIGITAL'
}

export function HandoffOptionsFromJSON(json: any): HandoffOptions {
    return HandoffOptionsFromJSONTyped(json, false);
}

export function HandoffOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandoffOptions {
    return json as HandoffOptions;
}

export function HandoffOptionsToJSON(value?: HandoffOptions | null): any {
    return value as any;
}

