import { ButtonHTMLAttributes, ReactElement, useContext, useState } from "react";
import RestaurantPageAddressButton from "./RestaurantPageAddressButton";
import FrameButton from "../../../components/buttons/FrameButton";
import { FaShoppingCart } from "react-icons/fa";
import { refreshMenuOrderCart } from "@/redux/meta/metaActions";
import { useDispatch } from "react-redux";
import DevourTopNavUserData from "@/components/DevourTopNavUserData";
import SignUpToast from "@/components/SignUpToast";
import { RestaurantContext } from "../context/RestaurantContext";
import classNames from "classnames";

interface Props {
    toggleShowCartPanel: () => void;
    sumQuantity: number;
}

function RestaurantPageTopBar(props: Props): ReactElement {
    const dispatch = useDispatch();
    const { isDevourStore, isDigitalStore } = useContext(RestaurantContext);
    const [showToast, setShowToast] = useState<boolean>(false);

    function handleToastDismissal() {
        setShowToast(false);
    }
    function handleToastShow() {
        setShowToast(true);
    }

    function onCartClick(): void {
        dispatch(refreshMenuOrderCart());
        props.toggleShowCartPanel();
    }

    return (
        <>
            <SignUpToast
                message="Join DevourGO and start your GoFriends journey!"
                isOpen={showToast}
                onDismiss={handleToastDismissal}
            />
            <div className={classNames("restaurant-page-top-bar", { "is-devour-store": isDevourStore })}>
                {isDevourStore
                    ? <h4 className="restaurant-page-top-bar_ds-title">Loot Shop</h4>
                    : <div className="restaurant-page-top-bar_logo">
                        <img
                            src={`${import.meta.env.VITE_CDN_URL}/images/DevourGO_Final-01.webp`}
                            alt="DevourGO Logo"
                        />
                    </div>
                }
                <div className="restaurant-page-top-bar_cart">
                    <div className="restaurant-page-top-bar_cart_button">
                        <FrameButton<ButtonHTMLAttributes<HTMLButtonElement>>
                            onClick={onCartClick}
                            color="purple"
                            size="narrow"
                            leftIcon={FaShoppingCart}
                            forwardProps={{ type: "button" }}
                        >
                            {props.sumQuantity}
                        </FrameButton>
                    </div>
                </div>
                <div className="restaurant-page-top-bar_account">
                    <DevourTopNavUserData
                        onShowSignInToast={handleToastShow}
                        dropdownAnchorElement=".restaurant-page-top-bar"
                        avatarOnly
                        hideWalletOnMobile={true}
                    />
                </div>
                {!isDigitalStore && <div className="restaurant-page-top-bar_address">
                    <RestaurantPageAddressButton />
                </div>}
            </div>
        </>
    );
}

export default RestaurantPageTopBar;
