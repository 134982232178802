/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AccountExperienceTransactionSubject {
    GLEAMREWARD = 'GLEAM_REWARD',
    CANDYBLAST = 'CANDY_BLAST',
    PIZZACAFE = 'PIZZA_CAFE',
    BURGERCAFE = 'BURGER_CAFE',
    RALLYCHAMPION = 'RALLY_CHAMPION',
    SPIN2WIN = 'SPIN2WIN',
    INTHEWEEDS = 'IN_THE_WEEDS',
    OTHER = 'OTHER',
    ADMINDEBIT = 'ADMIN_DEBIT',
    ADMINCREDIT = 'ADMIN_CREDIT',
    MENUORDERREWARD = 'MENU_ORDER_REWARD',
    LOADDPAY = 'LOAD_DPAY',
    INDUSTRYPASSPURCHASEREWARD = 'INDUSTRY_PASS_PURCHASE_REWARD',
    REFERAFRIENDFIRSTORDERREWARD = 'REFER_A_FRIEND_FIRST_ORDER_REWARD',
    SIGNUP = 'SIGN_UP',
    LEVELUP = 'LEVEL_UP',
    OVERWOLFTIMEREWARD = 'OVERWOLF_TIME_REWARD',
    OVERWOLFEVENTREWARD = 'OVERWOLF_EVENT_REWARD',
    OVERWOLFTIMEQUESTREWARD = 'OVERWOLF_TIME_QUEST_REWARD',
    DISCORDDAILYXP = 'DISCORD_DAILY_XP'
}

export function AccountExperienceTransactionSubjectFromJSON(json: any): AccountExperienceTransactionSubject {
    return AccountExperienceTransactionSubjectFromJSONTyped(json, false);
}

export function AccountExperienceTransactionSubjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountExperienceTransactionSubject {
    return json as AccountExperienceTransactionSubject;
}

export function AccountExperienceTransactionSubjectToJSON(value?: AccountExperienceTransactionSubject | null): any {
    return value as any;
}

