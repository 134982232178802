/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    ClaimOverwolfRewardResponse,
    ClaimOverwolfRewardResponseFromJSON,
    ClaimOverwolfRewardResponseToJSON,
    CreateOWUserEventBody,
    CreateOWUserEventBodyFromJSON,
    CreateOWUserEventBodyToJSON,
    CreateOverwolfGameBody,
    CreateOverwolfGameBodyFromJSON,
    CreateOverwolfGameBodyToJSON,
    CreateOverwolfGameImages,
    CreateOverwolfGameImagesFromJSON,
    CreateOverwolfGameImagesToJSON,
    GetOverwolfGamesResponse,
    GetOverwolfGamesResponseFromJSON,
    GetOverwolfGamesResponseToJSON,
    GetOverwolfXPResponse,
    GetOverwolfXPResponseFromJSON,
    GetOverwolfXPResponseToJSON,
    GetUserStatsResponse,
    GetUserStatsResponseFromJSON,
    GetUserStatsResponseToJSON,
    GetUserSummaryResponse,
    GetUserSummaryResponseFromJSON,
    GetUserSummaryResponseToJSON,
    OWGame,
    OWGameFromJSON,
    OWGameToJSON,
    OWReward,
    OWRewardFromJSON,
    OWRewardToJSON,
} from '../models';

export interface ClaimOverwolfRewardRequest {
    rewardId: string;
    claimedTarget?: number;
    gameId?: string;
}

export interface CreateOverwolfGameRequest {
    createOverwolfGameBody?: CreateOverwolfGameBody;
}

export interface GetOverwolfGameRequest {
    id: string;
}

export interface GetOverwolfGameRewardsRequest {
    id: string;
}

export interface GetOverwolfGamesRequest {
    search?: string;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetOverwolfUserStatsRequest {
    gameId?: string;
    rewardId?: string;
    rewardCategory?: GetOverwolfUserStatsRewardCategoryEnum;
}

export interface GetOverwolfXpRequest {
    subject: GetOverwolfXpSubjectEnum;
    overwolfRewardId?: string;
    notes?: string;
}

export interface LogOWUserEventRequest {
    createOWUserEventBody?: CreateOWUserEventBody;
}

export interface UpdateOverwolfGameRequest {
    id: string;
    createOverwolfGameBody?: CreateOverwolfGameBody;
}

export interface UpdateOverwolfGameImagesRequest {
    id: string;
    createOverwolfGameImages?: CreateOverwolfGameImages;
}

/**
 * OverwolfApi - interface
 * @export
 * @interface OverwolfApiInterface
 */
export interface OverwolfApiInterface {
    /**
     * Claims an Overwolf completed reward.
     * @param {string} rewardId 
     * @param {number} [claimedTarget] 
     * @param {string} [gameId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverwolfApiInterface
     */
    claimOverwolfRewardRaw(requestParameters: ClaimOverwolfRewardRequest): Promise<runtime.ApiResponse<ClaimOverwolfRewardResponse>>;

    /**
     * Claims an Overwolf completed reward.
     */
    claimOverwolfReward(requestParameters: ClaimOverwolfRewardRequest): Promise<ClaimOverwolfRewardResponse>;

    /**
     * Used to create new Overwolf game
     * @param {CreateOverwolfGameBody} [createOverwolfGameBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverwolfApiInterface
     */
    createOverwolfGameRaw(requestParameters: CreateOverwolfGameRequest): Promise<runtime.ApiResponse<OWGame>>;

    /**
     * Used to create new Overwolf game
     */
    createOverwolfGame(requestParameters: CreateOverwolfGameRequest): Promise<OWGame>;

    /**
     * Dismiss the weekly limit feedback for the user\'s Overwolf reward stats.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverwolfApiInterface
     */
    dismissWeeklyLimitFeedbackRaw(): Promise<runtime.ApiResponse<void>>;

    /**
     * Dismiss the weekly limit feedback for the user\'s Overwolf reward stats.
     */
    dismissWeeklyLimitFeedback(): Promise<void>;

    /**
     * Get list of all dual games
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverwolfApiInterface
     */
    getOverwolfDualGamesRaw(): Promise<runtime.ApiResponse<Array<OWGame>>>;

    /**
     * Get list of all dual games
     */
    getOverwolfDualGames(): Promise<Array<OWGame>>;

    /**
     * Retrieves Overwolf game data.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverwolfApiInterface
     */
    getOverwolfGameRaw(requestParameters: GetOverwolfGameRequest): Promise<runtime.ApiResponse<OWGame>>;

    /**
     * Retrieves Overwolf game data.
     */
    getOverwolfGame(requestParameters: GetOverwolfGameRequest): Promise<OWGame>;

    /**
     * Retrieves Overwolf rewards for a specific game.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverwolfApiInterface
     */
    getOverwolfGameRewardsRaw(requestParameters: GetOverwolfGameRewardsRequest): Promise<runtime.ApiResponse<Array<OWReward>>>;

    /**
     * Retrieves Overwolf rewards for a specific game.
     */
    getOverwolfGameRewards(requestParameters: GetOverwolfGameRewardsRequest): Promise<Array<OWReward>>;

    /**
     * Get list of all games
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverwolfApiInterface
     */
    getOverwolfGamesRaw(requestParameters: GetOverwolfGamesRequest): Promise<runtime.ApiResponse<GetOverwolfGamesResponse>>;

    /**
     * Get list of all games
     */
    getOverwolfGames(requestParameters: GetOverwolfGamesRequest): Promise<GetOverwolfGamesResponse>;

    /**
     * Retrieves Overwolf user stats.
     * @param {string} [gameId] Optional Overwolf game ID to filter user stats.
     * @param {string} [rewardId] Optional reward ID to filter user stats.
     * @param {'EVENT' | 'TIME'} [rewardCategory] Optional reward category (\&quot;EVENT\&quot; or \&quot;TIME\&quot;) to filter user stats.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverwolfApiInterface
     */
    getOverwolfUserStatsRaw(requestParameters: GetOverwolfUserStatsRequest): Promise<runtime.ApiResponse<GetUserStatsResponse>>;

    /**
     * Retrieves Overwolf user stats.
     */
    getOverwolfUserStats(requestParameters: GetOverwolfUserStatsRequest): Promise<GetUserStatsResponse>;

    /**
     * Gets the total xp, time and played games of the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverwolfApiInterface
     */
    getOverwolfUserSummaryRaw(): Promise<runtime.ApiResponse<GetUserSummaryResponse>>;

    /**
     * Gets the total xp, time and played games of the current user.
     */
    getOverwolfUserSummary(): Promise<GetUserSummaryResponse>;

    /**
     * Retrieves Overwolf-related account experience transactions for a user.
     * @param {'OVERWOLF_TIME_REWARD' | 'OVERWOLF_EVENT_REWARD' | 'OVERWOLF_TIME_QUEST_REWARD'} subject The subject of the transaction. Must be one of the specified enum values.
     * @param {string} [overwolfRewardId] Optional Overwolf reward ID to filter transactions.
     * @param {string} [notes] Optional notes to filter transactions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverwolfApiInterface
     */
    getOverwolfXpRaw(requestParameters: GetOverwolfXpRequest): Promise<runtime.ApiResponse<GetOverwolfXPResponse>>;

    /**
     * Retrieves Overwolf-related account experience transactions for a user.
     */
    getOverwolfXp(requestParameters: GetOverwolfXpRequest): Promise<GetOverwolfXPResponse>;

    /**
     * Creates a new Overwolf user event log.
     * @param {CreateOWUserEventBody} [createOWUserEventBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverwolfApiInterface
     */
    logOWUserEventRaw(requestParameters: LogOWUserEventRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Creates a new Overwolf user event log.
     */
    logOWUserEvent(requestParameters: LogOWUserEventRequest): Promise<void>;

    /**
     * Used to update existing Overwolf game
     * @param {string} id 
     * @param {CreateOverwolfGameBody} [createOverwolfGameBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverwolfApiInterface
     */
    updateOverwolfGameRaw(requestParameters: UpdateOverwolfGameRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to update existing Overwolf game
     */
    updateOverwolfGame(requestParameters: UpdateOverwolfGameRequest): Promise<void>;

    /**
     * Used to update existing Overwolf game images
     * @param {string} id 
     * @param {CreateOverwolfGameImages} [createOverwolfGameImages] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverwolfApiInterface
     */
    updateOverwolfGameImagesRaw(requestParameters: UpdateOverwolfGameImagesRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to update existing Overwolf game images
     */
    updateOverwolfGameImages(requestParameters: UpdateOverwolfGameImagesRequest): Promise<void>;

}

/**
 * no description
 */
export class OverwolfApi extends runtime.BaseAPI implements OverwolfApiInterface {

    /**
     * Claims an Overwolf completed reward.
     */
    async claimOverwolfRewardRaw(requestParameters: ClaimOverwolfRewardRequest): Promise<runtime.ApiResponse<ClaimOverwolfRewardResponse>> {
        if (requestParameters.rewardId === null || requestParameters.rewardId === undefined) {
            throw new runtime.RequiredError('rewardId','Required parameter requestParameters.rewardId was null or undefined when calling claimOverwolfReward.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.rewardId !== undefined) {
            queryParameters['rewardId'] = requestParameters.rewardId;
        }

        if (requestParameters.claimedTarget !== undefined) {
            queryParameters['claimedTarget'] = requestParameters.claimedTarget;
        }

        if (requestParameters.gameId !== undefined) {
            queryParameters['gameId'] = requestParameters.gameId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/overwolf/claim-reward`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClaimOverwolfRewardResponseFromJSON(jsonValue));
    }

    /**
     * Claims an Overwolf completed reward.
     */
    async claimOverwolfReward(requestParameters: ClaimOverwolfRewardRequest): Promise<ClaimOverwolfRewardResponse> {
        const response = await this.claimOverwolfRewardRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to create new Overwolf game
     */
    async createOverwolfGameRaw(requestParameters: CreateOverwolfGameRequest): Promise<runtime.ApiResponse<OWGame>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/overwolf/games`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOverwolfGameBodyToJSON(requestParameters.createOverwolfGameBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OWGameFromJSON(jsonValue));
    }

    /**
     * Used to create new Overwolf game
     */
    async createOverwolfGame(requestParameters: CreateOverwolfGameRequest): Promise<OWGame> {
        const response = await this.createOverwolfGameRaw(requestParameters);
        return await response.value();
    }

    /**
     * Dismiss the weekly limit feedback for the user\'s Overwolf reward stats.
     */
    async dismissWeeklyLimitFeedbackRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/overwolf/weekly-limit-feedback`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Dismiss the weekly limit feedback for the user\'s Overwolf reward stats.
     */
    async dismissWeeklyLimitFeedback(): Promise<void> {
        await this.dismissWeeklyLimitFeedbackRaw();
    }

    /**
     * Get list of all dual games
     */
    async getOverwolfDualGamesRaw(): Promise<runtime.ApiResponse<Array<OWGame>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Optional", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/overwolf/dual-games`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OWGameFromJSON));
    }

    /**
     * Get list of all dual games
     */
    async getOverwolfDualGames(): Promise<Array<OWGame>> {
        const response = await this.getOverwolfDualGamesRaw();
        return await response.value();
    }

    /**
     * Retrieves Overwolf game data.
     */
    async getOverwolfGameRaw(requestParameters: GetOverwolfGameRequest): Promise<runtime.ApiResponse<OWGame>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOverwolfGame.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/overwolf/games/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OWGameFromJSON(jsonValue));
    }

    /**
     * Retrieves Overwolf game data.
     */
    async getOverwolfGame(requestParameters: GetOverwolfGameRequest): Promise<OWGame> {
        const response = await this.getOverwolfGameRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieves Overwolf rewards for a specific game.
     */
    async getOverwolfGameRewardsRaw(requestParameters: GetOverwolfGameRewardsRequest): Promise<runtime.ApiResponse<Array<OWReward>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOverwolfGameRewards.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/overwolf/games/{id}/rewards`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OWRewardFromJSON));
    }

    /**
     * Retrieves Overwolf rewards for a specific game.
     */
    async getOverwolfGameRewards(requestParameters: GetOverwolfGameRewardsRequest): Promise<Array<OWReward>> {
        const response = await this.getOverwolfGameRewardsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get list of all games
     */
    async getOverwolfGamesRaw(requestParameters: GetOverwolfGamesRequest): Promise<runtime.ApiResponse<GetOverwolfGamesResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/overwolf/games`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOverwolfGamesResponseFromJSON(jsonValue));
    }

    /**
     * Get list of all games
     */
    async getOverwolfGames(requestParameters: GetOverwolfGamesRequest): Promise<GetOverwolfGamesResponse> {
        const response = await this.getOverwolfGamesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieves Overwolf user stats.
     */
    async getOverwolfUserStatsRaw(requestParameters: GetOverwolfUserStatsRequest): Promise<runtime.ApiResponse<GetUserStatsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.gameId !== undefined) {
            queryParameters['gameId'] = requestParameters.gameId;
        }

        if (requestParameters.rewardId !== undefined) {
            queryParameters['rewardId'] = requestParameters.rewardId;
        }

        if (requestParameters.rewardCategory !== undefined) {
            queryParameters['rewardCategory'] = requestParameters.rewardCategory;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/overwolf/user-stats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserStatsResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves Overwolf user stats.
     */
    async getOverwolfUserStats(requestParameters: GetOverwolfUserStatsRequest): Promise<GetUserStatsResponse> {
        const response = await this.getOverwolfUserStatsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets the total xp, time and played games of the current user.
     */
    async getOverwolfUserSummaryRaw(): Promise<runtime.ApiResponse<GetUserSummaryResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/overwolf/user-summary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserSummaryResponseFromJSON(jsonValue));
    }

    /**
     * Gets the total xp, time and played games of the current user.
     */
    async getOverwolfUserSummary(): Promise<GetUserSummaryResponse> {
        const response = await this.getOverwolfUserSummaryRaw();
        return await response.value();
    }

    /**
     * Retrieves Overwolf-related account experience transactions for a user.
     */
    async getOverwolfXpRaw(requestParameters: GetOverwolfXpRequest): Promise<runtime.ApiResponse<GetOverwolfXPResponse>> {
        if (requestParameters.subject === null || requestParameters.subject === undefined) {
            throw new runtime.RequiredError('subject','Required parameter requestParameters.subject was null or undefined when calling getOverwolfXp.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.overwolfRewardId !== undefined) {
            queryParameters['overwolfRewardId'] = requestParameters.overwolfRewardId;
        }

        if (requestParameters.subject !== undefined) {
            queryParameters['subject'] = requestParameters.subject;
        }

        if (requestParameters.notes !== undefined) {
            queryParameters['notes'] = requestParameters.notes;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/overwolf/xp`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOverwolfXPResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves Overwolf-related account experience transactions for a user.
     */
    async getOverwolfXp(requestParameters: GetOverwolfXpRequest): Promise<GetOverwolfXPResponse> {
        const response = await this.getOverwolfXpRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a new Overwolf user event log.
     */
    async logOWUserEventRaw(requestParameters: LogOWUserEventRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/overwolf/user-events`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOWUserEventBodyToJSON(requestParameters.createOWUserEventBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates a new Overwolf user event log.
     */
    async logOWUserEvent(requestParameters: LogOWUserEventRequest): Promise<void> {
        await this.logOWUserEventRaw(requestParameters);
    }

    /**
     * Used to update existing Overwolf game
     */
    async updateOverwolfGameRaw(requestParameters: UpdateOverwolfGameRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOverwolfGame.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/overwolf/games/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOverwolfGameBodyToJSON(requestParameters.createOverwolfGameBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to update existing Overwolf game
     */
    async updateOverwolfGame(requestParameters: UpdateOverwolfGameRequest): Promise<void> {
        await this.updateOverwolfGameRaw(requestParameters);
    }

    /**
     * Used to update existing Overwolf game images
     */
    async updateOverwolfGameImagesRaw(requestParameters: UpdateOverwolfGameImagesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOverwolfGameImages.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/overwolf/games/{id}/images`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOverwolfGameImagesToJSON(requestParameters.createOverwolfGameImages),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to update existing Overwolf game images
     */
    async updateOverwolfGameImages(requestParameters: UpdateOverwolfGameImagesRequest): Promise<void> {
        await this.updateOverwolfGameImagesRaw(requestParameters);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetOverwolfUserStatsRewardCategoryEnum {
    EVENT = 'EVENT',
    TIME = 'TIME'
}
/**
    * @export
    * @enum {string}
    */
export enum GetOverwolfXpSubjectEnum {
    TIMEREWARD = 'OVERWOLF_TIME_REWARD',
    EVENTREWARD = 'OVERWOLF_EVENT_REWARD',
    TIMEQUESTREWARD = 'OVERWOLF_TIME_QUEST_REWARD'
}
