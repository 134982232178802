import {
    ReactElement,
    useEffect,
    useState,
} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    CommerceApi,
    DpayPurchase,
    DpayPurchaseRequestBody,
    ErrorType,
    TokenPaymentMethod,
    TransactionsApi,
} from "@devour/client";
import {StripePaymentMethodObject} from "@/types/Stripe";
import {IStore} from "@/redux/defaultStore";
import {useGetTransactionsPaginated} from "@/hooks/useGetTransactionsPaginated";
import {useGetUserLevel} from "@/hooks/useGetUserLevel";
import {addError, decrementLoading, incrementLoading, makeApiError} from "@/redux/meta/metaActions";
import getConfig from "@/utils/getConfig";
import classNames from "classnames";
import {LoadStates} from "@/components/loadDpay/LoadStates";
import LoadDpaySuccess from "@/components/loadDpay/LoadDpaySuccess";
import CardPaymentAddModal from "@/components/modals/CardPaymentAddModal";
import LoadDpayFailedModal from "@/components/modals/LoadDpayFailedModal";
import PaymentAuthenticationModal from "@/components/modals/PaymentAuthenticationModal";
import FrameModalNoHeaderToggle from "@/components/modals/modalComponents/FrameModalNoHeaderToggle";
import LoadDpayConfirmModal from "@/components/modals/LoadDpayConfirmModal";
import LoadDpaySelectPayment from "@/components/loadDpay/LoadDpaySelectPayment";
import {useGate} from "statsig-react";
import FrameButton from "@/components/buttons/FrameButton";
import FuelUpWithXp from "@/components/FuelUpWithXpModal";

const defaultValues: DpayPurchaseRequestBody = {
    dpay: 0,
    fiat: 25,
    xp: 0,
    paymentMethod: TokenPaymentMethod.FIAT,
    paymentMethodId: "",
};

interface Props {
    toggleAsModal?: () => void;
}

export const minXpToLoadFuel = 10;

function LoadDpayPanel(props: Props): ReactElement {
    const dispatch = useDispatch();
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser?.user);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { value: dpayStatus } = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);
    const [
        errorMessage,
        setErrorMessage,
    ] = useState<string>("");
    const [
        loadState,
        setLoadState,
    ] = useState<LoadStates>(LoadStates.SELECT_PAYMENT);
    const [
        formValues,
        setFormValues,
    ] = useState<DpayPurchaseRequestBody>(defaultValues);
    const [
        dpayPurchase,
        setDpayPurchase,
    ] = useState<DpayPurchase>(undefined);
    const [
        disclaimerCheck,
        setDisclaimerCheck,
    ] = useState<boolean>(false);
    const [
        countdown,
        setCountdown,
    ] = useState<string>("");
    const [
        paymentMethods,
        setPaymentMethods,
    ] = useState<Array<StripePaymentMethodObject>>([]);
    const [
        selectedPayment,
        setSelectedPayment,
    ] = useState<StripePaymentMethodObject>(undefined);
    const [
        showAddCardModal,
        setShowAddCardModal,
    ] = useState<boolean>(false);
    const [
        showLoadFailed,
        setShowLoadFailed,
    ] = useState<boolean>(false);
    const [
        paymentNextAction,
        setPaymentNextAction,
    ] = useState<string>("");

    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

    const {
        refetch: refetchTransactionsData,
    } = useGetTransactionsPaginated(fullToken, currentUser?.id);
    const {refetch: refetchUserLevel} = useGetUserLevel(fullToken, currentUser?.id);

    useEffect(() => {
        if (currentUser) {
            void fetchPaymentMethods();
        }
        void fetchPrice(formValues);
    }, []);

    useEffect(() => {
        // Not start the countdown when the page is just loaded because by the time user decides to buy DPAY, there may be not enough time
        if (!selectedPayment) return;
        const interval = setInterval(() => {
            const distance = dpayPurchase?.expiresAt - Date.now() || 0;
            if (distance > 0) {
                const minutes = Math.floor(distance % (1000 * 60 * 60) / (1000 * 60));
                const seconds = Math.floor(distance % (1000 * 60) / 1000);
                setCountdown(`${minutes}m ${seconds}s`);
            } else {
                setCountdown(undefined);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [
        dpayPurchase,
        selectedPayment?.id,
    ]);

    useEffect(() => {
        if (loadState === LoadStates.SELECT_PAYMENT) {
            setSelectedPayment(undefined);
            void fetchPrice(formValues);
        }
    }, [loadState]);

    async function fetchPaymentMethods(selectedPaymentId?: string): Promise<void> {
        dispatch(incrementLoading());
        try {
            const res = await new CommerceApi(getConfig()).stripePaymentMethodList();
            const paymentMethods = res.paymentMethods as Array<StripePaymentMethodObject>;
            setPaymentMethods(paymentMethods);
            if (selectedPaymentId) {
                const paymentMethod: StripePaymentMethodObject = paymentMethods.find(method => method.id === selectedPaymentId);
                setSelectedPayment(paymentMethod);
            }
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    async function fetchPrice(values: DpayPurchaseRequestBody): Promise<void> {
        if (!dpayStatus) {
            setLoadState(LoadStates.DPAY_ERROR);
            return;
        }
        dispatch(incrementLoading());
        try {
            const res = await new TransactionsApi(getConfig()).upsertDpayPurchase({
                dpayPurchaseRequestBody: values,
            });
            setDpayPurchase(res);
            setFormValues({
                ...formValues,
                dpay: res.dpay,
                fiat: res.fiat,
                xp: res.xp,
                paymentMethod: res.paymentMethod,
                paymentMethodId: res.paymentMethodId,
            });
        } catch (e) {
            const _error = await makeApiError(e);
            setErrorMessage(_error.message);
            setLoadState(LoadStates.DPAY_ERROR);
            // Reset form value to previous value
            setFormValues({
                ...formValues,
                dpay: dpayPurchase?.dpay,
                fiat: dpayPurchase?.fiat,
                xp: dpayPurchase?.xp,
            });
        } finally {
            setDisclaimerCheck(false);
            dispatch(decrementLoading());
        }
    }


    async function onAddPaymentMethodSubmit(paymentMethodId: string): Promise<void> {
        setShowAddCardModal(false);
        await fetchPaymentMethods(paymentMethodId);
        await fetchPrice({paymentMethodId: paymentMethodId});
    }


    async function onPaymentVerify(displayError: boolean): Promise<void> {
        if (!dpayPurchase) {
            return;
        }
        dispatch(incrementLoading());
        try {
            await new TransactionsApi(getConfig()).verifyDpayPurchase({
                id: dpayPurchase.id,
            });
            setPaymentNextAction("");
            await refetchTransactionsData();
            await refetchUserLevel();
            setLoadState(LoadStates.SUCCESS);
            setFormValues(defaultValues);
        } catch (e) {
            if (displayError) {
                dispatch(await addError({
                    type: ErrorType.APP,
                    message: "Payment is not complete. Please try again with a different card or contact DevourGO support.",
                }));
            }
        } finally {
            dispatch(decrementLoading());
        }

    }

    async function onSubmit(): Promise<void> {
        dispatch(incrementLoading());
        try {
            const res = await new TransactionsApi(getConfig()).submitDpayPurchase({
                id: dpayPurchase.id,
            });
            if (res.nextAction) {
                setPaymentNextAction(res.nextAction);
            } else {
                void refetchTransactionsData();
                void refetchUserLevel();
                setLoadState(LoadStates.SUCCESS);
                setFormValues(defaultValues);
            }
        } catch (e) {
            setShowLoadFailed(true);
        } finally {
            dispatch(decrementLoading());
        }
    }

    function toggleConfirmModal() {
        setShowConfirmModal(s => !s);
    }


    function renderContents() {

        switch (loadState) {
            case LoadStates.DPAY_ERROR:
                return (
                    <div>
                        {errorMessage ||
                            <>
                                {import.meta.env.VITE_TOKEN_NAME} loading is currently unavailable.<br/>
                                Please try again later or contact DevourGO support.
                            </>
                        }

                        <FrameButton
                            color="purple"
                            size="narrow"
                            className="buy-dpay-page_about_content_industry-pass_button"
                            forwardProps={{type: "button"}}
                            onClick={() => setLoadState(LoadStates.SELECT_PAYMENT)}
                        >
                            Retry
                        </FrameButton>
                    </div>
                );
            case LoadStates.SELECT_PAYMENT:
                return (
                    <LoadDpaySelectPayment
                        fetchPrice={fetchPrice}
                        dpayPurchase={dpayPurchase}
                        selectedPayment={selectedPayment}
                        setSelectedPayment={setSelectedPayment}
                        setShowAddCardModal={setShowAddCardModal}
                        setShowConfirmModal={setShowConfirmModal}
                        disclaimerCheck={disclaimerCheck}
                        setDisclaimerCheck={setDisclaimerCheck}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        paymentMethods={paymentMethods}
                        countdown={countdown}
                    />
                );
            default:
                return (
                    <LoadDpaySuccess
                        setLoadState={setLoadState}
                        toggleAsModal={props.toggleAsModal}
                        dpayPurchase={dpayPurchase}
                        payment={selectedPayment}
                    />
                );
        }
    }

    return (
        <>
            <FuelUpWithXp
                loadWithXp={async () => await fetchPrice({
                    paymentMethod: TokenPaymentMethod.XP,
                    fiat: undefined,
                    xp: minXpToLoadFuel,
                })}
            />
            <CardPaymentAddModal
                isOpen={showAddCardModal}
                onClose={() => setShowAddCardModal(false)}
                onSubmit={onAddPaymentMethodSubmit}
            />
            <LoadDpayFailedModal
                isOpen={showLoadFailed}
                toggle={() => setShowLoadFailed(s => !s)}
            />

            <LoadDpayConfirmModal
                isOpen={showConfirmModal}
                toggle={toggleConfirmModal}
                onConfirm={onSubmit}
            />

            <PaymentAuthenticationModal
                paymentNextAction={paymentNextAction}
                isOpen={!!paymentNextAction}
                onVerify={onPaymentVerify}
                onClose={() => window.location.reload()}
            />
            <div className={classNames("buy-dpay-page_panel", {
                "buy-dpay-page_panel_regular-padding": !props.toggleAsModal,
                "buy-dpay-page_panel_modal-padding": props.toggleAsModal,
            })}>
                {props.toggleAsModal && <FrameModalNoHeaderToggle toggle={props.toggleAsModal}/>}
                {renderContents()}
            </div>
        </>
    );
}

export default LoadDpayPanel;
