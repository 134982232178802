import {ReactElement, useContext} from "react";
import {HandoffOptions, MenuOrder, Restaurant} from "@devour/client";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {RestaurantContext} from "../context/RestaurantContext";
import {getDistanceFromLatLonInKm} from "@/utils/getDistanceFromLatLonInKm";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {useRestaurant} from "@/hooks/useRestaurant";
import classNames from "classnames";

export const kmInMile = 1.609;

export const MINS_PER_MILE = 3;


/**
 * Pickup distance between customer and restaurant, in km.
 */
export function getDeliveryDistance(restaurant: Restaurant, menuOrder: MenuOrder): number {
    if (restaurant?.distance) {
        return restaurant.distance;
    }
    if (restaurant && menuOrder) {
        const originLng = restaurant.address.location.coordinates[0];
        const originLat = restaurant.address.location.coordinates[1];
        const destinationLng = menuOrder.address.location.coordinates[0];
        const destinationLat = menuOrder.address.location.coordinates[1];
        return getDistanceFromLatLonInKm(originLat, originLng, destinationLat, destinationLng);
    }
    return 0;
}


function RestaurantHandoffOptionsDetails(): ReactElement {

    const {restaurantId, menuOrderId, isDigitalStore} = useContext(RestaurantContext);
    const {data: restaurant} = useRestaurant(restaurantId);
    const {data: menuOrder} = useMenuOrder(menuOrderId);
    const handoff = useSelector((store: IStore) => store.metaStore.handoff);
    const realHandoff = isDigitalStore ? HandoffOptions.DIGITAL : menuOrder?.handoff || handoff;

    const getDeliveryCharge = () => {
        if (menuOrder?.deliveryCharge !== undefined) {
            return `$${menuOrder.deliveryCharge?.toFixed(2)}`;
        }
        if (restaurant?.deliveryCharge !== undefined) {
            return `$${restaurant.deliveryCharge?.toFixed(2)}`;
        }
        return "-";
    };

    const renderInfoLeft = () => {
        if (realHandoff === HandoffOptions.DIGITAL) {
            return (
                <div>
                    <div className="restaurant-handoff-options_info_value">
                        Email
                    </div>
                    <div className="restaurant-handoff-options_info_label">
                                                Delivery Method
                    </div>
                </div>
            );
        }
        if (realHandoff === HandoffOptions.DELIVERY) {
            return (
                <div>
                    <div className="restaurant-handoff-options_info_value">
                        {getDeliveryCharge()}
                    </div>
                    <div className="restaurant-handoff-options_info_label">
														Delivery fee
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className="restaurant-handoff-options_info_value">
                    {(getDeliveryDistance(restaurant, menuOrder) / kmInMile).toFixed(1)} miles
                </div>
                <div className="restaurant-handoff-options_info_label">
                                        From you
                </div>
            </div>
        );
    };

    const renderInfoRight = () => {
        if (realHandoff === HandoffOptions.DIGITAL) {
            return (
                <div>
                    <div className="restaurant-handoff-options_info_value">
                   24-48 hours
                    </div>
                    <div className="restaurant-handoff-options_info_label">
                    Order Processing TIme
                    </div>
                </div>
            );
        }
        if (realHandoff === HandoffOptions.DELIVERY) {
            return (
                <div>
                    <div className="restaurant-handoff-options_info_value">
                        {restaurant.prepTime + Math.ceil(getDeliveryDistance(restaurant, menuOrder) / kmInMile) * MINS_PER_MILE} mins
                    </div>
                    <div className="restaurant-handoff-options_info_label">
                                            Avg. time
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className="restaurant-handoff-options_info_value">
                    {restaurant.prepTime} mins
                </div>
                <div className="restaurant-handoff-options_info_label">
                                        Avg. time
                </div>
            </div>
        );
    };

    if (!restaurant) {
        return null;
    }
    return (
        <div className="restaurant-handoff-options_info" >
            <div className="restaurant-handoff-options_info_left">
                {renderInfoLeft()}
            </div>
            <div className="restaurant-handoff-options_info_divider"/>
            <div className="restaurant-handoff-options_info_right">
                {renderInfoRight()}
            </div>
        </div>
    );
}

export default RestaurantHandoffOptionsDetails;
