import React, {CSSProperties, Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useState} from "react";
import {OverwolfApi, OWReward, OWRewardMilestone, OWUserStat} from "@devour/client";
import classNames from "classnames";
import {RiVipDiamondLine} from "react-icons/ri";
import CircularProgressIndicator from "../CircularProgress";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import FrameButton from "../buttons/FrameButton";
import {MdCheck} from "react-icons/md";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import getConfig from "@/utils/getConfig";
import useGetOverwolfUserStats from "@/hooks/useGetOverwolfUserStats";
import Toast from "@/components/Toast";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {useGetUserLevel} from "@/hooks/useGetUserLevel";
import {store} from "@/redux";
import {updateAccountLevel, updateCurrentUser} from "@/redux/meta/metaActions";
import {BsTriangleFill} from "react-icons/bs";
import AnimateHeight from "react-animate-height";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import Spacer from "@/components/Spacer";
import {findLastIndex} from "lodash";
import {useGetOverwolfSummary} from "@/hooks/useGetOverwolfSummary";
import {getTimeUntilMonthlyReset, nextResetTime, ResetTimeUnit} from "@/utils/devourPlay/getTimeUntilMonthlyReset";
import {FaRegClock} from "react-icons/fa6";

interface Props {
    quest: OWReward;
    userStat?: OWUserStat;
    className?: string;
    alwaysMobile?: boolean;
    gameId: string;
    isClaimingInProgress: boolean;
    setIsClaimingInProgress: Dispatch<SetStateAction<boolean>>;
}

const testResetTime = {
    unit: ResetTimeUnit.DAY,
    value: 3,
    str: "3 days",
};

export default function GoVipOWQuestRewardCard(props: Props) {
    const {quest, userStat} = props;
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [claimingToast, setClaimingToast] = useState<{ message: string, success: boolean }>(undefined);
    const [isQuestCollapsed, setIsQuestCollapsed] = useState<boolean>(true);
    const {refetch: refetchUserStats} = useGetOverwolfUserStats(fullToken, props.gameId, false);
    const {refetch: refetchUserProfile} = useGetUserProfile(fullToken, true);
    const {refetch: refetchSummary} = useGetOverwolfSummary();
    const {refetch: refetchUserLevel} = useGetUserLevel(fullToken, store.getState().metaStore.currentUser?.user.id, true);
    const isOnMobile = isMobile && !isTablet || props.alwaysMobile;
    const {isOnOverwolf} = useOverwolfInterop();
    const numOfMilestones = quest.milestones?.length || 0;
    const userProgress = userStat?.progress || 0;
    // For Monthly Reset Tag
    const [nextResetTime, setNextResetTime] = useState<nextResetTime | null>(null);
    const FIVE_MINUTES_MS = 5 * 60 * 1000;

    const showResetWarningColor = () => {
        if (nextResetTime?.unit === ResetTimeUnit.DAY && nextResetTime.value > 3) {
            return false;
        } else {
            return true;
        }
    };

    const lastMilestoneCompletedIndex = useMemo(
        () => findLastIndex(quest.milestones ?? [], m => m.target <= userProgress),
        [quest.id, userProgress],
    );

    const completedDate: string = new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
    }).format(userStat?.updatedAt);

    useEffect(() => {
        setNextResetTime(getTimeUntilMonthlyReset());

        // Set reset time every 5 mins
        const intervalId = setInterval(() => {
            setNextResetTime(getTimeUntilMonthlyReset());
        }, FIVE_MINUTES_MS);

        return () => clearInterval(intervalId);
    }, []);

    /**
     * Checks if a user has any unclaimed milestone rewards based on their current progress
     * @param userStat
     * @param quest
     * @returns boolean indicating if there are any unclaimed rewards
     */
    function hasUnclaimedRewards(
        userStat: OWUserStat,
        quest: OWReward,
    ): boolean {
        // Get all milestones that should be claimable based on current progress
        const claimableMilestones = quest.milestones.filter(milestone => userStat.progress >= milestone.target);

        // Get all milestone targets that have been claimed
        const claimedTargets = new Set(userStat.claimLogs.map(log => log.target));

        // Check if there are any claimable milestones that haven't been claimed yet
        return claimableMilestones.some(milestone => !claimedTargets.has(milestone.target));
    }

    async function claimOverwolfReward(target?: number) {
        props.setIsClaimingInProgress(true);
        try {
            const res = await new OverwolfApi(getConfig(fullToken)).claimOverwolfReward({
                rewardId: quest.id,
                claimedTarget: numOfMilestones > 0 ? target : undefined,
                gameId: props.gameId,
            });
            await refetchUserStats();
            await refetchSummary();
            setClaimingToast({
                message: `${res.rewardAmount} ${props.quest.rewardType} successfully claimed!`,
                success: true,
            });
            void updateUserLevel();
        } catch (e) {
            setClaimingToast({
                message: "There has been a problem claiming your reward. Please try again later!",
                success: false,
            });
        } finally {
            props.setIsClaimingInProgress(false);
        }
    }

    async function updateUserLevel() {
        const [
            updatedUserProfile,
            updatedUserLevel,
        ] = await Promise.all([refetchUserProfile(), refetchUserLevel()]);
        if (updatedUserProfile.data) {
            dispatch(updateCurrentUser(updatedUserProfile.data));
        }

        if (updatedUserLevel.data) {
            dispatch(updateAccountLevel(updatedUserLevel.data));
        }
    }

    const getCurrentProgress = () => {
        if (!props.userStat) {
            return 0;
        }
        if (props.userStat.progress < props.quest.target) {
            return props.userStat.progress;
        }
        return quest.target;
    };

    const renderClaimButton = (target: number, isClaimed?: boolean) => {
        if (!fullToken || !userStat || userStat.progress < target) {
            return null;
        }

        if (userStat?.isClaimed || isClaimed) {
            return (
                <FrameButton
                    size="narrow"
                    className={classNames(
                        "go-vip-ow_quest-reward-card_claimed",
                        {
                            "desktop-claimed-btn": isOnOverwolf && !isOnMobile,
                        },
                    )}
                >
                    Claimed on {completedDate} {(isOnOverwolf || isDesktop) && <MdCheck/>}
                </FrameButton>
            );
        }

        return (
            <FrameButton
                size="narrow"
                onClick={() => claimOverwolfReward(target)}
                color="devourplay-gradient"
                className={classNames(
                    "go-vip-ow_quest-reward-card_claim-btn",
                    {
                        // Mobile claim button should take up full width 100% (not on desktop)
                        "mobile-claim-btn": !isOnOverwolf || isOnMobile,
                    },
                )}
                forwardProps={{disabled: props.isClaimingInProgress}}
            >
                Claim
            </FrameButton>
        );
    };

    const getCircularProgressGlow = () => {
        return props.userStat && props.userStat.progress >= props.quest.target && !props.userStat.isClaimed;
    };

    const renderResetTag = (top: boolean = false) => {
        if (!nextResetTime || !quest.isMonthlyQuest || !userStat || userStat.progress === 0) return null;

        const hasUnclaimedXP = hasUnclaimedRewards(userStat, quest);

        return <div className={classNames(
            "go-vip-ow_quest-reward-card_reset-tag",
            {
                "top": top,
                "urgent": showResetWarningColor(),
            },
        )}>
            <FaRegClock /> Resets in {nextResetTime.str} {(hasUnclaimedXP && showResetWarningColor()) && "- Claim your XP!"}
        </div>;
    };

    function renderIcon(): ReactElement {
        if (numOfMilestones) {
            return <>
                <Spacer/>
                <BsTriangleFill
                    className={classNames(
                        "go-vip-ow_quest-reward-card_info_icon",
                        "go-vip-ow_quest_milestones_collapse-icon", {
                            "go-vip-ow_quest_milestones_collapse-icon_flipped": !isQuestCollapsed,
                        },
                    )}
                    onClick={() => setIsQuestCollapsed(!isQuestCollapsed)}
                />
            </>;
        }
    }

    function getToastStyle(): CSSProperties {
        if (isOnMobile && isOnOverwolf) {
            return {
                maxWidth: "100vw",
                width: "100vw",
                borderRadius: "0",
                marginBottom: "0",
                marginLeft: "0",
            };
        }

        return {
            marginBottom: "0",
        };
    }

    return (
        <>
            <Toast
                message={claimingToast?.message}
                isOpen={!!claimingToast}
                onDismiss={() => setClaimingToast(undefined)}
                removeMarginAdjustment={true}
                className={classNames(
                    "overwolf-govip_rewards_claim",
                    {"overwolf-govip_rewards_claim_in-game": isOnMobile && isOnOverwolf},
                )}
                forceDarkMode={true}
                variant={claimingToast?.success ? "success" : "error"}
                style={isOnOverwolf && getToastStyle()}
            />
            <div
                className={classNames("go-vip-ow_quest-reward-card", {
                    "disabled": !fullToken,
                    "bottom-spacing": !isQuestCollapsed,
                })}>
                <div>
                    {(!isOnOverwolf || isOnMobile) &&
                        renderResetTag(true)
                    }
                </div>

                <div className="go-vip-ow_quest-reward-card_main">
                    <div className="go-vip-ow_quest-reward-card_info">
                        <CircularProgressIndicator
                            showGlow={getCircularProgressGlow()}
                            total={props.quest.target}
                            current={getCurrentProgress()}
                        />
                        <div className="go-vip-ow_quest-reward-card_info_content">
                            <strong className="go-vip-ow_quest-reward-card_info_content_title">
                                {props.quest.name}
                                {isOnOverwolf && !isOnMobile &&
                                    renderResetTag()
                                }
                            </strong>
                            <p className="go-vip-ow_quest-reward-card_info_content_description">
                                {props.quest.description}
                            </p>

                            <strong className={classNames(
                                "go-vip-ow_quest-reward-card_info_content_points",
                                {"no-user-stats": !props.userStat},
                            )}
                            >
                                <RiVipDiamondLine className={!props.userStat && "no-user-stats"}/>
                                {props.quest.rewardAmount} {props.quest.rewardType}
                            </strong>
                        </div>

                        {renderIcon()}
                    </div>

                    {fullToken && !numOfMilestones && <div className="go-vip-ow_quest-reward-card_action">
                        {renderClaimButton(quest.target, props.userStat?.isClaimed)}
                    </div>}
                </div>

                {numOfMilestones > 0 &&
                    <AnimateHeight
                        duration={300}
                        height={isQuestCollapsed ? 0 : "auto"}
                    >
                        <div className="go-vip-ow_quest_milestones">
                            <div className="go-vip-ow_quest_milestones_progress">
                                {Array.from({length: numOfMilestones}, (_, i) =>
                                    <div
                                        className={classNames("go-vip-ow_quest_milestones_progress_marker", {
                                            "completed": userStat?.progress >= quest.milestones[i]?.target,
                                        })}
                                        key={`${quest.id}-marker-${i}`}
                                    />)}
                                <div
                                    className="go-vip-ow_quest_milestones_progress_bar"
                                    style={{height: `${lastMilestoneCompletedIndex / (numOfMilestones - 1) * 100}%`}}
                                />
                            </div>
                            <div className="go-vip-ow_quest_milestones_list">
                                {quest.milestones.map((milestone: OWRewardMilestone, index) =>
                                    <React.Fragment key={`${quest.id}-${milestone.target}`}>
                                        <div
                                            key={`${quest.id}-${milestone.target}`}
                                            className={`go-vip-ow_quest_milestones_milestone ${quest.id}-${milestone.target}`}
                                        >
                                            <div className="go-vip-ow_quest_milestones_milestone_col1">
                                                <p>{milestone.name}</p>
                                                <strong
                                                    className="go-vip-ow_quest_milestones_milestone_points go-vip-ow_quest-reward-card_info_content_points">
                                                    <RiVipDiamondLine/> {milestone.amount} {props.quest.rewardType}
                                                </strong>
                                                {(!isOnOverwolf || isOnMobile) && renderClaimButton(milestone.target, !!userStat?.claimLogs?.find(log => log.target === milestone.target))}
                                            </div>
                                            {!isOnMobile && isOnOverwolf && renderClaimButton(milestone.target, !!userStat?.claimLogs?.find(log => log.target === milestone.target))}
                                        </div>
                                        {index !== numOfMilestones - 1 &&
                                            <div className="go-vip-ow_quest_milestones_divider"/>}
                                    </React.Fragment>)}
                            </div>
                        </div>
                    </AnimateHeight>
                }
            </div>
        </>
    );
}
