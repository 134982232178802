import {
    ButtonHTMLAttributes,
    Dispatch,
    ReactElement,
    SetStateAction,
    useContext,
    useEffect,
    useRef,
} from "react";
import {
    GetMenuResponse,
    MenuCategory,
    MenuItem,
} from "@devour/client";
import RestaurantMenuCategoriesListJumper from "../../../components/RestaurantMenuCategoriesListJumper";
import FrameButton from "../../../components/buttons/FrameButton";
import {AiOutlineSearch} from "react-icons/ai";
import {isMobile, isTablet} from "react-device-detect";
import {RestaurantContext} from "../context/RestaurantContext";
import {useRestaurant} from "@/hooks/useRestaurant";

interface Props {
    setMobileSearchMode: Dispatch<SetStateAction<boolean>>;
    restaurantMenu: GetMenuResponse;
    searchValue: string;
    onSticky: boolean;
}

/**
 * Observer to trigger when element is sticky.
 * https://stackoverflow.com/a/56678169
 */
const observer = new IntersectionObserver(
    ([e]) => e.target.toggleAttribute("stuck", e.intersectionRatio < 1),
    {threshold: [1]},
);

function RestaurantMenusJumper(props: Props): ReactElement {

    const {restaurantId, isDigitalStore} = useContext(RestaurantContext);
    const {data: restaurant} = useRestaurant(restaurantId);
    const menuCategoriesRoot = props.restaurantMenu?.menus?.filter((category) => category.isEnabled);
    const stickyRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        if (stickyRef.current) {
            observer.observe(stickyRef.current);
        }
        return () => {
            if (stickyRef.current) {
                observer.unobserve(stickyRef.current);
            }
        };
    }, [stickyRef]);

    /**
     * Get only categories & sub categories than have any items that match the user's search.
     *
     * @param _sortedRootCats
     */
    function getFilteredCategories(_sortedRootCats: Array<MenuCategory>): Array<MenuCategory> {
        if (!_sortedRootCats) {
            return [];
        }
        return _sortedRootCats.reduce((acc: Array<MenuCategory>, category) => {
            const foundMenuItems = category.menuItems?.filter(item => filterItem(item, props.searchValue));

            if (foundMenuItems.length > 0) {
                acc.push(category);
            }

            if (category.menuSubcategories.length > 0) {
                acc = acc.concat(getFilteredCategories(category.menuSubcategories));
            }

            return acc;
        }, []);
    }

    function filterItem(item: MenuItem, search: string): boolean {
        if (!item.isEnabled) {
            return false;
        }
        return item?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
            item.description?.toLowerCase()?.includes(search.toLowerCase());
    }

    if (!restaurant) {
        return null;
    }

    const filteredCategories = getFilteredCategories(menuCategoriesRoot);

    return (

        <div>
            {isMobile && !isTablet &&
            <FrameButton
				    <ButtonHTMLAttributes<HTMLButtonElement>>
				    color="white-drop-shadow"
				    size="normal"
				    onClick={() => props.setMobileSearchMode(true)}
				    className="restaurant-menus-jumper_search"
				    forwardProps={{type: "button"}}
				    leftIcon={AiOutlineSearch}
            >
                {isDigitalStore ? "Search an item" : "Search menu"}
            </FrameButton>
            }
            <div className="restaurant-menus-jumper_cat-list">
                <RestaurantMenuCategoriesListJumper
                    categories={filteredCategories}
                    onSticky={props.onSticky}
                />
            </div>
        </div>
    );
}

export default RestaurantMenusJumper;
